import React, { useCallback, useState } from 'react';
import { toQuery, useMediaQuery } from 'react-responsive';

import {
  GetPickUPTimes,
  GetPickUPTimesForTabs,
  getFulfillment,
  getMenu,
  getMenuCalendar,
  setFulfillment,
} from '@/entities/menu';
import { getOrderMenuDate, setChoosenMeal, setOrderMenuDate } from '@/entities/menu/filter';

import { fetchMenu } from '@/shared/api/menu';
import { ModalOrderAhead } from '@/shared/components/ModalOrderAhead';
import type {
  THandleFetchMenu,
  THandlePickUpTimes,
} from '@/shared/components/ModalOrderAhead/types';
import { FULFILLMENT_METHODS, FulfillmentType, MODE } from '@/shared/constants';
import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';
import { ModalService } from '@/shared/services/modal';
import type { TMenuInitialData } from '@/shared/types/menu';

import { CartButton } from './CartButton';
import { DailyMeal } from './DailyMeal';
import { Filters } from './Filters';
import { MealCalculator } from './MealCalculator';
import { NavigationBar } from './Navigation';
import { Tabs } from './Tabs';
import { FilterSeparator, WrapperFilters } from './parts';

const PanelTabFilters: React.FC = () => {
  const {
    model: {
      DisplayDailyMenu,
      DisplayWeeklyMenu,
      DisplayFoodOrderMenu,
      SelectedPeriodId,
      IsFoodOrder,
      IsWeekly,
      FulfillmentDelivery,
      FulfillmentPickup,
      OnlyAllowFutureDaysOnlineOrdering,
      OnlyShowCurrentDaysOnlineOrdering,
    },
    periods,
  } = useAppSelector(getMenu) as TMenuInitialData;
  const dispatch = useAppDispatch<MenuAppDispatch>();

  const isMobile = useMediaQuery({
    query: toQuery({
      maxWidth: 767,
    }),
  });

  const selectTab = (DisplayDailyMenu || DisplayWeeklyMenu) && !IsFoodOrder ? 0 : 1;

  const [tabIndex, setTabIndex] = useState(selectTab);

  const { FOOD_ORDER } = MODE;

  const fulfillment = useAppSelector(getFulfillment);
  const orderMenuDate = useAppSelector(getOrderMenuDate);
  const menuCalendar = useAppSelector(getMenuCalendar);

  const handlerPickUPTimesModeFoodOrder = useCallback(
    (lastIndex: number) => {
      const handleOrderMenuDate = (v: string | null): void => {
        dispatch(setOrderMenuDate(v));
      };
      const handleFulfillment = (v: FulfillmentType): void => {
        dispatch(setFulfillment(v));
      };
      const handlePickUpTimes: THandlePickUpTimes = (v) => {
        dispatch(GetPickUPTimes(v));
      };
      const handleFetchMenu: THandleFetchMenu = (v) => {
        dispatch(fetchMenu(v));
      };
      const selectedTabIndex =
        FulfillmentPickup &&
        fulfillment.SelectedFulfillmentMethod === FULFILLMENT_METHODS.PICK_UP.name
          ? FULFILLMENT_METHODS.PICK_UP.const
          : FULFILLMENT_METHODS.DELIVERY.const;

      dispatch(
        GetPickUPTimesForTabs({
          mode: FOOD_ORDER,
          periodId: SelectedPeriodId ? Number(SelectedPeriodId) : null,
          fulfillmentMethod: selectedTabIndex,
          callback: (defaultPickUPTimes) => {
            ModalService.open(ModalOrderAhead, {
              setActiveTab: setTabIndex,
              tabIndexParent: lastIndex,
              OnlyShowCurrentDaysOnlineOrdering,
              OnlyAllowFutureDaysOnlineOrdering,
              isPickUpTab: FulfillmentPickup,
              isDeliveryTab: FulfillmentDelivery,
              defaultPickUPTimes,
              fulfillment,
              orderMenuDate,
              setOrderMenuDate: handleOrderMenuDate,
              setChoosenMeal,
              mode: MODE.FOOD_ORDER,
              setFulfillment: handleFulfillment,
              menuCalendar,
              selectedTabIndex,
              handlePickUpTimes,
              handleFetchMenu,
            });
          },
        }),
      );
    },
    [
      FulfillmentPickup,
      fulfillment,
      dispatch,
      FOOD_ORDER,
      SelectedPeriodId,
      OnlyShowCurrentDaysOnlineOrdering,
      OnlyAllowFutureDaysOnlineOrdering,
      FulfillmentDelivery,
      orderMenuDate,
      menuCalendar,
    ],
  );

  const content = (
    <>
      <Tabs
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        handlerPickUPTimesModeFoodOrder={handlerPickUPTimesModeFoodOrder}
        IsFoodOrder={IsFoodOrder}
        DisplayDailyMenu={DisplayDailyMenu}
        DisplayFoodOrderMenu={DisplayFoodOrderMenu}
        DisplayWeeklyMenu={DisplayWeeklyMenu}
        defaultDayInPeriod={periods[0]}
      />
      <WrapperFilters>
        <DailyMeal handlerPickUPTimesModeFoodOrder={handlerPickUPTimesModeFoodOrder} />
        {!IsWeekly && <MealCalculator />}
        <Filters>
          <CartButton />
        </Filters>
      </WrapperFilters>
      <FilterSeparator />
    </>
  );

  return !IsWeekly ? (
    <>
      {isMobile && content}
      <NavigationBar>{!isMobile && content}</NavigationBar>
    </>
  ) : (
    content
  );
};
export default PanelTabFilters;
