import React from 'react';
import { useIntl } from 'react-intl';

import { Button } from '@/shared/components/Button';
import { ModalOne } from '@/shared/services/modal';
import type { TPortalModalComponent } from '@/shared/services/modal/type';
import type { TProducts } from '@/shared/types/menu';

import ProductTile from '../ProductTile';
import { WrapperGoesWellWith } from '../parts';

interface IProps {
  products: TProducts;
}

const GoesWellWith: TPortalModalComponent<IProps> = ({ products, close }) => {
  const { formatMessage } = useIntl();
  const messages = {
    title: formatMessage({ id: 'Menus.Modals.GoesWellWith.Title' }),
    button: formatMessage({ id: 'Menus.Modals.GoesWellWith.Button.Title' }),
  };

  const MAX_NUMBER_OF_DISPLAYED = 3;
  const list = products.slice(0, MAX_NUMBER_OF_DISPLAYED);
  const productList = list.map((product) => {
    return <ProductTile key={product.MenuProductId || product.ProductId} product={product} />;
  });
  return (
    <ModalOne
      title={<span dangerouslySetInnerHTML={{ __html: messages.title }} />}
      handlerClose={close}
      footer={
        <Button
          width="auto"
          height="40px"
          padding="8px 24px"
          fontWeight="bold"
          borderRadius="4px"
          className="Cancel"
          click={close}
        >
          <span dangerouslySetInnerHTML={{ __html: messages.button }} />
        </Button>
      }
      isFocusOnCloseButton
    >
      <WrapperGoesWellWith>{productList}</WrapperGoesWellWith>
    </ModalOne>
  );
};

export default GoesWellWith;
