import { setMenu } from '@/entities/menu';
import { setFilters } from '@/entities/menu/filter';
import { setProducts } from '@/entities/menu/product';

import type { TMenuInitialData } from '@/shared/types/menu';

export const firstLoadOfMenu =
  (payload: TMenuInitialData): MenuAppThunk =>
  (dispatch) => {
    dispatch(setMenu(payload));
    dispatch(setFilters(payload));
    if (!payload.model.IsFoodOrder) dispatch(setProducts(payload.model.Menu.MenuProducts));
  };
