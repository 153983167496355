import format from 'date-fns/format';

import { activeStantions } from '@/entities/menu/product';

import { FORMAT_DATE_TIME, MODE } from '@/shared/constants';
import type { TMenuInitialData, TMenuStations } from '@/shared/types/menu';
import { getWeeks } from '@/shared/utils/parseDate';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { IFiltersSlice } from '../type';

const weeks = getWeeks();

const initialState: IFiltersSlice = {
  choosenMeal: null,
  choosenView: MODE.DAILY,
  dailyMenuDate: null,
  orderMenuDate: null,
  weeklyMenuStartDate: weeks[0].startOfWeek,
  weeklyMenuEndDate: weeks[0].endOfWeek,
  filters: {
    allergens: [],
    specialDiets: [],
  },
  searchText: '',
};
const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<TMenuInitialData>) => {
      state.choosenMeal = {
        name: action.payload.model.Menu.MenuPeriods.filter(
          (p) => p.PeriodId === action.payload.model.SelectedPeriodId,
        )[0]?.Name,
        id: action.payload.model.SelectedPeriodId
          ? Number(action.payload.model.SelectedPeriodId)
          : null,
      };
      state.dailyMenuDate = format(new Date(), FORMAT_DATE_TIME.MMDDYYYY);
      state.orderMenuDate = format(new Date(), FORMAT_DATE_TIME.MMDDYYYY);
      state.choosenView = action.payload.defaultMode as MODE;
      state.filters = {
        allergens: action.payload.model.Menu.Allergens,
        specialDiets: action.payload.model.Menu.SpecialDiets,
      };
    },
    setChoosenMeal: (state, action: PayloadAction<IFiltersSlice['choosenMeal']>) => {
      state.choosenMeal = action.payload;
    },
    setChoosenView: (state, action: PayloadAction<IFiltersSlice['choosenView']>) => {
      state.choosenView = action.payload;
    },
    setDailyMenuDate: (state, action: PayloadAction<IFiltersSlice['dailyMenuDate']>) => {
      state.dailyMenuDate = action.payload
        ? format(new Date(action.payload), FORMAT_DATE_TIME.MMDDYYYY)
        : null;
    },
    setOrderMenuDate: (state, action: PayloadAction<IFiltersSlice['orderMenuDate']>) => {
      state.orderMenuDate = action.payload
        ? format(new Date(action.payload), FORMAT_DATE_TIME.MMDDYYYY)
        : null;
    },
    setWeeklyMenuStartDate: (
      state,
      action: PayloadAction<IFiltersSlice['weeklyMenuStartDate']>,
    ) => {
      state.weeklyMenuStartDate = action.payload;
    },
    setWeeklyMenuEndDate: (state, action: PayloadAction<IFiltersSlice['weeklyMenuEndDate']>) => {
      state.weeklyMenuEndDate = action.payload;
    },
    setFrontFilters: (state, action: PayloadAction<IFiltersSlice['filters']>) => {
      state.filters = action.payload;
    },
    setSearchText: (state, action: PayloadAction<IFiltersSlice['searchText']>) => {
      state.searchText = action.payload;
    },
  },
});

export const {
  setFilters,
  setChoosenMeal,
  setChoosenView,
  setDailyMenuDate,
  setOrderMenuDate,
  setWeeklyMenuStartDate,
  setWeeklyMenuEndDate,
  setSearchText,
} = filterSlice.actions;

export const { setFrontFilters } = filterSlice.actions;

export const getActiveStantionsForFilter = (state: MenuRootState): TMenuStations =>
  activeStantions(state, true);

export const getChoosenMeal = ({ filters }: MenuRootState): IFiltersSlice['choosenMeal'] =>
  filters.choosenMeal;
export const getChoosenView = ({ filters }: MenuRootState): IFiltersSlice['choosenView'] =>
  filters.choosenView;
export const getDailyMenuDate = ({ filters }: MenuRootState): IFiltersSlice['dailyMenuDate'] =>
  filters.dailyMenuDate;
export const getOrderMenuDate = ({ filters }: MenuRootState): IFiltersSlice['orderMenuDate'] =>
  filters.orderMenuDate;
export const getWeeklyMenuStartDate = ({
  filters,
}: MenuRootState): IFiltersSlice['weeklyMenuStartDate'] => filters.weeklyMenuStartDate;
export const getWeeklyMenuEndDate = ({
  filters,
}: MenuRootState): IFiltersSlice['weeklyMenuEndDate'] => filters.weeklyMenuEndDate;
export const getFilters = ({ filters }: MenuRootState): IFiltersSlice['filters'] => filters.filters;
export const getSearchText = ({ filters }: MenuRootState): IFiltersSlice['searchText'] =>
  filters.searchText;

export default filterSlice.reducer;
