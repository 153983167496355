import React, { useMemo } from 'react';

import { ProductTile } from '@/entities/menu/product';

import type { TProducts } from '@/shared/types/menu';

import { MenuItemsDiv } from '../parts';

interface IProps {
  products: TProducts;

  margin?: string;
}
export const MenuItemsPart: React.FC<IProps> = ({ products, margin }) => {
  const elementsProducts = useMemo(() => {
    return products.map((product) => (
      <ProductTile key={product.MenuProductId} product={product} margin={margin} />
    ));
  }, [products, margin]);

  return (
    <MenuItemsDiv margin={margin} className="MenuItemsDiv">
      {elementsProducts}
    </MenuItemsDiv>
  );
};
