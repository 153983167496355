import format from 'date-fns/format';

import {
  GetPickUPTimes,
  GetPickUPTimesForTabs,
  setCurrentCartItemsQuantity,
  setFulfillment,
} from '@/entities/menu';
import { setChoosenMeal, setOrderMenuDate } from '@/entities/menu/filter';
import { GoesWellWith } from '@/entities/menu/product';

import { addFoodProductToCart, fetchMenu } from '@/shared/api/menu';
import { ModalOrderAhead } from '@/shared/components/ModalOrderAhead';
import { FORMAT_DATE_TIME, FULFILLMENT_METHODS, MODE } from '@/shared/constants';
import { setLoading } from '@/shared/services/loading';
import { ModalService } from '@/shared/services/modal';
import { NOTIFICATIONS, NotificationService } from '@/shared/services/notification';
import { BasketPreiewAction } from '@/shared/utils/basketPreiew';

import type { TActionAddFoodProductToCart } from './types';

export const AddFoodProductToCart: TActionAddFoodProductToCart =
  (
    {
      Product,
      UserOrderingInfo,
      StoreId,
      Configuration,
      RecipientInstructions,
      PickupTimeIncrement,
      DeliveryLeadTimeFrame,
      DeliveryLeadTime,
    },
    callback,
    showWarningMsg,
  ) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    const { locationId, pageStoreId } = getState().menu.requestParameters;
    const fulfillment = getState().menu.Fulfillment;
    const {
      SelectedFulfillmentMethod,
      PickUpFulfillment,
      DeliveryFulfillment,
      mealType,
      PickUpLocation,
      PeriodId,
      SelectedDate,
      DeliveryLocation,
    } = fulfillment;
    const method =
      SelectedFulfillmentMethod === 'Pickup'
        ? {
            PickUpFulfillment: { ...PickUpFulfillment, RecipientInstructions, PickupTimeIncrement },
          }
        : { DeliveryFulfillment: { ...DeliveryFulfillment, RecipientInstructions } };
    const model = getState().menu.Menu?.model;
    const { PICK_UP, DELIVERY } = FULFILLMENT_METHODS;
    const payload = {
      RequestParameters: {
        Date: SelectedDate && format(new Date(SelectedDate), FORMAT_DATE_TIME.MMDDYYYY),
        LocationId: locationId,
        PeriodId: String(PeriodId),
        StoreId: pageStoreId || '',
      },
      SelectedDate,
      Product,
      UserOrderingInfo,
      LocationId: locationId,
      StoreId,
      PeriodId,
      Configuration,
      mealType,
      SelectedFulfillmentMethod,
      PickUpLocation,
      DeliveryLocation,
      DeliveryLeadTimeFrame,
      DeliveryLeadTime,
      ...method,
    };
    try {
      const response = await addFoodProductToCart(payload);
      const { Errors, isSlotExpired, crossSellProducts, showErrorInModal, msg } = response;
      if (showErrorInModal) {
        showWarningMsg(msg);
        return;
      }

      if (isSlotExpired) {
        const fulfillmentMethod =
          SelectedFulfillmentMethod === PICK_UP.name ? PICK_UP.const : DELIVERY.const;
        callback();
        dispatch(
          GetPickUPTimesForTabs({
            mode: MODE.FOOD_ORDER,
            periodId: PeriodId,
            fulfillmentMethod,
            callback: (defaultPickUPTimes) => {
              ModalService.open(ModalOrderAhead, {
                OnlyShowCurrentDaysOnlineOrdering: !!model?.OnlyShowCurrentDaysOnlineOrdering,
                OnlyAllowFutureDaysOnlineOrdering: !!model?.OnlyAllowFutureDaysOnlineOrdering,
                isPickUpTab: !!model?.FulfillmentPickup,
                isDeliveryTab: !!model?.FulfillmentDelivery,
                defaultPickUPTimes,
                fulfillment,
                setFulfillment: (v) => dispatch(setFulfillment(v)),
                setOrderMenuDate: (v) => dispatch(setOrderMenuDate(v)),
                orderMenuDate: getState().filters.orderMenuDate,
                setChoosenMeal: (v) => dispatch(setChoosenMeal(v)),
                mode: MODE.FOOD_ORDER,
                isSlotExpired: response.isSlotExpired,
                msgForSlotExpired: response.msg,
                menuCalendar: getState().menu.MenuCalendar,
                selectedTabIndex: fulfillmentMethod,
                handlePickUpTimes: (v) => dispatch(GetPickUPTimes(v)),
                handleFetchMenu: (v) => dispatch(fetchMenu(v)),
              });
            },
          }),
        );

        return;
      }

      if (Array.isArray(Errors) && Errors.length > 0) {
        Errors.forEach((errorMsg) => {
          NotificationService.open({
            status: NOTIFICATIONS.ERROR,
            text: errorMsg,
          });
        });
        return;
      }

      callback();

      if (Array.isArray(crossSellProducts) && crossSellProducts.length > 0) {
        ModalService.open(GoesWellWith, {
          products: response.crossSellProducts,
        });
      }

      dispatch(setCurrentCartItemsQuantity(response.currentCartItemsQuantity ?? null));

      await BasketPreiewAction();

      setTimeout(() => {
        const cartButton = document.querySelector<HTMLButtonElement>('.react-checkout-button');
        cartButton?.focus();
      }, 100);
    } catch (error) {
      NotificationService.open({
        status: NOTIFICATIONS.ERROR,
        text: (error as Error).message,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
