import React from 'react';

import { MenuPage } from '@/pages/menu';

import { AppProviders } from '@/shared/components/Providers';
import { APP_ELEMENTS } from '@/shared/constants';
import { Modal } from '@/shared/services/modal';
import { Notification } from '@/shared/services/notification';
import { MenuTheme } from '@/shared/themes';

import store from './store';

const App: React.FC = () => {
  return (
    <AppProviders resources={window.menus.resources} store={store} theme={MenuTheme}>
      <MenuPage />
      <Modal appElement={APP_ELEMENTS.MENUS} />
      <Notification />
    </AppProviders>
  );
};

export default App;
