import styled, { css } from 'styled-components';

import type { IDefaultTheme } from '@/shared/themes/types';

export const MealCalculatorButton = styled.button`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    width: 100%;
    padding: 4px 2px 2px 21px;
    margin: 1px 1px 16px 1px;
    border: none;
    height: 40px;
    border-radius: 22.5px;
    background-color: #efefef;
    display: inline-flex;
    box-sizing: border-box;
    position: relative;

    ${theme.media.tablet} {
      margin: 1px 16px 1px 1px;
      width: auto;
      padding: 1px 2px 2px 16px;
    }
  `}
`;

export const WrapperSVG = styled.div`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    width: 56px;
    height: 38px;
    padding: 2px 16px;
    border-radius: 0px 22.5px 22.5px 0px;
    background-color: #ffffff;
    border: none;
    margin: 0;
    outline: none;
    position: absolute;
    right: 1px;
    top: 1px;

    ${theme.media.tablet} {
      position: relative;
      right: 0;
      top: 0;
    }
  `}
`;

export const ModalActionButton = styled.button`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${theme.defaultPrimaryColor};
    float: right;
    border: none;
    background: none;
  `}
`;

export const ModalBodySpan = styled.span`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
`;

export const ModalBodySpanBold = styled(ModalBodySpan)`
  font-weight: bold;
`;
