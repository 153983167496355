import React, { PropsWithChildren, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { toQuery, useMediaQuery } from 'react-responsive';

import { getIsWeekly } from '@/entities/menu';
import { getActiveStantionsForFilter } from '@/entities/menu/filter';

import { StickyNavigation } from '@/shared/components/StickyNavigation';
import { useAppSelector } from '@/shared/hooks/appRedux';

import Search from '../Search';
import { ButtonGear } from './blocks/ButtonGear';
import { NavigationCustom, OverflowNavWrapper } from './parts';

const SUFFIX = '_sticky';

const NavigationBar: React.FC<PropsWithChildren> = ({ children }) => {
  const { formatMessage } = useIntl();
  const favoritesTitle = formatMessage({ id: 'Favorites' });
  const activeStantions = useAppSelector(getActiveStantionsForFilter);

  const isWeekly = useAppSelector(getIsWeekly);
  const [isGear, setGear] = useState(false);

  const options = useMemo(
    () =>
      activeStantions.map((station, index) => {
        const { StationId, Name } = station;

        const nameStation = StationId === '1' ? favoritesTitle : Name;
        const active = index === 0;
        return { id: StationId, name: nameStation, active };
      }),
    [activeStantions, favoritesTitle],
  );

  const isMobile = useMediaQuery({
    query: toQuery({
      maxWidth: 767,
    }),
  });
  const headerRef = useRef<HTMLElement>(null);
  const isShowGear = useMemo(() => isMobile && isGear, [isGear, isMobile]);

  const removeSuffix = (text: string): string => {
    const regex = new RegExp(`${SUFFIX}$`);
    return text.replace(regex, '');
  };
  const scrollToStationByID = (stickyItemId: string): void => {
    const stationNode = document.getElementById(removeSuffix(stickyItemId));
    if (stationNode) {
      const stationRect = stationNode.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      const offset = stationRect.top - bodyRect.top;
      window.scrollTo(0, offset - (headerRef.current?.offsetHeight || 0));
    }
  };

  const setFocusStation = (
    e: React.KeyboardEvent<HTMLButtonElement>,
    stickyItemId: string,
  ): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      scrollToStationByID(stickyItemId);
      const anchorNode = document.getElementById(removeSuffix(stickyItemId));
      const listAll = anchorNode?.querySelectorAll('li');
      if (listAll) {
        for (let i = 0; i < listAll.length; i++) {
          if (listAll[i].querySelector('button') != null) {
            listAll[i].getElementsByTagName('button')[0].focus();
            break;
          }
        }
      }
    }
  };

  const prevId = useRef('0');

  const handleActiveAnchor = (): Nullable<string> => {
    const listOffsets = options.reduce<{ offset: number; id: string }[]>((acc, item) => {
      const element = document.getElementById(item.id);
      if (element) {
        const bodyRect = document.body.getBoundingClientRect();
        const elemRect = element.getBoundingClientRect();
        const offset = elemRect.top - bodyRect.top;
        if (offset < window.pageYOffset + (headerRef.current?.offsetHeight || 0) + 2) {
          return [...acc, { offset, id: element.id }];
        }
      }

      return acc;
    }, []);

    const curId = listOffsets.at(-1)?.id;

    if (curId && curId !== prevId.current) {
      prevId.current = curId;
      return curId;
    }
    return null;
  };

  const handleScroll = (): Nullable<string> => {
    if (isMobile && headerRef.current) {
      const headerRect = headerRef.current.getBoundingClientRect();
      setGear(headerRect.top <= 0);
    }

    const id = handleActiveAnchor();
    return id ? `${id}${SUFFIX}` : null;
  };

  return (
    <NavigationCustom ref={headerRef} className="NavigationBar">
      {children}
      <StickyNavigation
        options={options.map((opt) => ({
          ...opt,
          id: `${opt.id}${SUFFIX}`,
        }))}
        handleClick={scrollToStationByID}
        handleKeyDown={setFocusStation}
        handleScroll={handleScroll}
      >
        {!isWeekly && <Search />}
      </StickyNavigation>
      <OverflowNavWrapper>{isShowGear && <ButtonGear />}</OverflowNavWrapper>
    </NavigationCustom>
  );
};

export default NavigationBar;
