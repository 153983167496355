import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { getMenu } from '@/entities/menu';
import {
  getChoosenMeal,
  getDailyMenuDate,
  getOrderMenuDate,
  getWeeklyMenuEndDate,
  getWeeklyMenuStartDate,
  setChoosenMeal,
} from '@/entities/menu/filter';
import { getIsProducts } from '@/entities/menu/product';

import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';
import { useFocus } from '@/shared/hooks/focus';
import { ModalService } from '@/shared/services/modal';
import { parseDate, parseWeek } from '@/shared/utils/parseDate';

import { TMenuPeriods } from '../../types';
import { MainWrapper, TextSpan } from '../parts';
import DineInModal from './modal/DineInModal';
import { DateMealFilterButton, DateMealFilterButtonWrapper, DateMealFilterSpan } from './parts';

interface IProps {
  handlerPickUPTimesModeFoodOrder: (value: number) => void;
}
const DailyMealBlock: React.FC<IProps> = ({ handlerPickUPTimesModeFoodOrder }) => {
  const {
    model: {
      Menu: { MenuPeriods },
      IsDaily,
      IsFoodOrder,
      SelectedPeriodId,
    },
  } = useAppSelector(getMenu) as {
    model: {
      Menu: { MenuPeriods: TMenuPeriods };
      IsDaily: boolean;
      IsFoodOrder: boolean;
      SelectedPeriodId: Nullable<string>;
    };
  };
  const dispatch = useAppDispatch<MenuAppDispatch>();

  const choosenMeal = useAppSelector(getChoosenMeal);

  const menuDate = useAppSelector(getDailyMenuDate);
  const orderMenuDate = useAppSelector(getOrderMenuDate);

  const startDate = useAppSelector(getWeeklyMenuStartDate);
  const endDate = useAppSelector(getWeeklyMenuEndDate);
  const isProducts = useAppSelector(getIsProducts);

  const isShowTextSelectOrderingOptions = !isProducts && !choosenMeal?.name;

  const { formatMessage } = useIntl();

  const [inputRef, setInputFocus] = useFocus<HTMLButtonElement>();

  useEffect(() => {
    const name = MenuPeriods.filter((p) => p.PeriodId === SelectedPeriodId)[0]?.Name;
    const meal = {
      name,
      id: SelectedPeriodId ? Number(SelectedPeriodId) : null,
    };
    dispatch(setChoosenMeal(meal));
  }, [MenuPeriods, SelectedPeriodId, dispatch]);

  const HandleDateMealFilterButtonClick = (): void => {
    if (IsFoodOrder) {
      handlerPickUPTimesModeFoodOrder(1);
      return;
    }

    ModalService.open(DineInModal, {
      setInputFocus,
    });
  };

  const date = IsFoodOrder ? orderMenuDate : menuDate;

  const textDate = IsDaily || IsFoodOrder ? parseDate(date) : parseWeek(startDate, endDate);

  const messages = {
    change: formatMessage({ id: 'Menus.Filters.DateMealFilter.Buttons.Change' }),
    selectOrderingOptions: formatMessage({
      id: 'Menus.Filters.DateMealFilter.Title.SelectOrderingOptions',
    }),
    buttonAriaDescription: formatMessage(
      { id: 'Menus.Filters.DateMealFilter.Button.AriaLabel' },
      { 0: textDate, 1: choosenMeal?.name },
    ),
  };

  const locationText = isShowTextSelectOrderingOptions ? (
    <TextSpan
      className="SelectOrderingOptions"
      dangerouslySetInnerHTML={{
        __html: messages.selectOrderingOptions,
      }}
    />
  ) : (
    <>
      <TextSpan className="TextDate">{textDate}</TextSpan>
      <TextSpan className="ChoosenMeal">{choosenMeal?.name}</TextSpan>
    </>
  );

  return (
    <MainWrapper className="DateMealFilter">
      <DateMealFilterButtonWrapper className="DateMealFilterButtonWrapper" tabIndex={-1}>
        {locationText}
        <DateMealFilterButton
          className="DateMealFilterButton"
          onClick={HandleDateMealFilterButtonClick}
          ref={inputRef}
          tabIndex={0}
          aria-describedby="DateFilterButtonID"
        >
          <span id="DateFilterButtonID" className="sr-only">
            {messages.buttonAriaDescription}
          </span>
          <DateMealFilterSpan
            dangerouslySetInnerHTML={{
              __html: messages.change,
            }}
          />
        </DateMealFilterButton>
      </DateMealFilterButtonWrapper>
    </MainWrapper>
  );
};

export default DailyMealBlock;
