import React from 'react';
import { useIntl } from 'react-intl';

import {
  getTotalMeal,
  getTotalMealIds,
  setTotalMeal,
  setTotalMealIds,
} from '@/entities/menu/product';

import { AmountCalories } from '@/shared/components/AmountCalories';
import { ButtonSecond } from '@/shared/components/Button';
import { Underline } from '@/shared/components/Underline';
import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';
import { ModalOne } from '@/shared/services/modal';
import { TPortalModalComponent } from '@/shared/services/modal/type';

import { Disclaimer, ModalBodyWrapper, WrapperButtons, WrapperContent } from '../../parts';
import IconMealCalculator from '../blocks/IconMealCalculator';
import { ModalActionButton, ModalBodySpan, ModalBodySpanBold } from '../parts';

interface IProps {
  setInputFocus: VoidFunction;
}

const MealCaculatorModal: TPortalModalComponent<IProps> = ({ close, setInputFocus }) => {
  const productInTotalMeal = useAppSelector(getTotalMeal)?.Product;
  const calories = productInTotalMeal?.NutritionalTree;
  const totalMeal = useAppSelector(getTotalMealIds)?.length;
  const { formatMessage } = useIntl();
  const messages = {
    ok: formatMessage({ id: 'Ok' }),
    mealCalculator: formatMessage({ id: 'mealCalculator' }),
    toGetStarted: formatMessage({ id: 'ToGetStarted' }),
    emptyMessage: formatMessage({ id: 'EmptyMessage' }),
    menuDisclaimer: formatMessage({ id: 'menuDisclaimer' }),
    amountCaloriesTitle: formatMessage({
      id: 'Menus.Filters.MealCalculator.Labels.AmountCalories',
    }),
    clear: formatMessage({ id: 'Menus.Filters.MealCalculator.Button.Clear' }),
    totalMeal: formatMessage(
      { id: 'Menus.Modals.MealCalculator.Labels.TotalMeal' },
      { count: totalMeal },
    ),
  };
  const dispatch = useAppDispatch<MenuAppDispatch>();
  const handleClearClick = (): void => {
    dispatch(setTotalMealIds(null));
    dispatch(setTotalMeal(null));
  };

  const handleClose = (): void => {
    close();
    setInputFocus();
  };
  return (
    <ModalOne
      title={
        <span
          dangerouslySetInnerHTML={{
            __html: messages.mealCalculator,
          }}
        />
      }
      handlerClose={close}
      footer={
        <WrapperButtons>
          <ButtonSecond isDark className="Ok" click={handleClose}>
            <span
              dangerouslySetInnerHTML={{
                __html: messages.ok,
              }}
            />
          </ButtonSecond>
        </WrapperButtons>
      }
      isFocusOnCloseButton
    >
      <ModalBodyWrapper className="BodyWrapper">
        <WrapperContent>
          <ModalBodySpan>
            {totalMeal === 0 && (
              <p>
                <span
                  dangerouslySetInnerHTML={{
                    __html: messages.emptyMessage,
                  }}
                />
                <IconMealCalculator />
                <span
                  dangerouslySetInnerHTML={{
                    __html: messages.toGetStarted,
                  }}
                />
              </p>
            )}
            {totalMeal > 0 && (
              <div>
                <ModalBodySpanBold id="MealCaculatorTotalItem">
                  {messages.totalMeal}
                </ModalBodySpanBold>
                <ModalActionButton
                  id="MealCaculatorClearButton"
                  aria-labelledby="MealCaculatorClearButton MealCaculatorTotalItem"
                  onClick={handleClearClick}
                >
                  {messages.clear}
                </ModalActionButton>
                <Underline />
                {calories && calories.length && (
                  <AmountCalories calories={calories} title={messages.amountCaloriesTitle} />
                )}
                <Disclaimer
                  dangerouslySetInnerHTML={{
                    __html: messages.menuDisclaimer.toUpperCase(),
                  }}
                />
              </div>
            )}
          </ModalBodySpan>
        </WrapperContent>
      </ModalBodyWrapper>
    </ModalOne>
  );
};

export default MealCaculatorModal;
