import React from 'react';

import type { TMenuStation } from '@/shared/types/menu';

import { StationHeader, StationHeaderTitle } from '../../CommonMenu/parts';

interface IProps {
  station: TMenuStation;
  toPrint: boolean;
}
export const WeeklyMenuItemStation: React.FC<IProps> = ({ station, toPrint }) => {
  return (
    <StationHeader className="StationHeader">
      <StationHeaderTitle className="StationHeaderTitle" toPrint={toPrint}>
        {station.Name}
      </StationHeaderTitle>
    </StationHeader>
  );
};
