import format from 'date-fns/format';
import React, { useMemo } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { GetMenuPeriods, PRE_SELECTED, getMenu } from '@/entities/menu';
import {
  getChoosenMeal,
  getChoosenView,
  getDailyMenuDate,
  getWeeklyMenuEndDate,
  getWeeklyMenuStartDate,
  setChoosenMeal,
  setChoosenView,
  setDailyMenuDate,
  setWeeklyMenuEndDate,
  setWeeklyMenuStartDate,
} from '@/entities/menu/filter';

import { fetchMenu } from '@/shared/api/menu';
import { Button, ButtonSecond } from '@/shared/components/Button';
import { Select } from '@/shared/components/Select';
import type { TCustomSelectForwardRef } from '@/shared/components/Select/types';
import { FORMAT_DATE_TIME, MODE } from '@/shared/constants';
import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';
import { useFocus } from '@/shared/hooks/focus';
import { ModalOne } from '@/shared/services/modal';
import type { TPortalModalComponent } from '@/shared/services/modal/type';
import { getWeeks } from '@/shared/utils/parseDate';

import { TMealOptions, TMenuPeriods } from '../../../types';
import { ButtonOutline, ModalBodyWrapper, ModalSubheader, WrapperContent } from '../../parts';
import DailyBlock from '../blocks/DailyBlock';
import { MealFilter } from '../parts';

interface IProps {
  setInputFocus: VoidFunction;
}

const DineInModal: TPortalModalComponent<IProps> = ({ close, setInputFocus }) => {
  const {
    model: {
      Menu: { MenuPeriods },
      StoreIds,
      DisplayDailyMenu,
      DisplayWeeklyMenu,
      DisplayOnlyCurrentDayMenu,
    },
  } = useAppSelector(getMenu) as {
    model: {
      Menu: { MenuPeriods: TMenuPeriods };
      StoreIds: string;
      DisplayWeeklyMenu: boolean;
      DisplayDailyMenu: boolean;
      DisplayOnlyCurrentDayMenu: boolean;
    };
  };

  const choosenMeal = useAppSelector(getChoosenMeal);
  const menuDate = useAppSelector(getDailyMenuDate);
  const startDate = useAppSelector(getWeeklyMenuStartDate);
  const endDate = useAppSelector(getWeeklyMenuEndDate);
  const choosenView = useAppSelector(getChoosenView);

  const weeks = getWeeks();

  const { DAILY_MEAL_MEAL, DAILY_MEAL_VIEW, DAILY_MEAL_START_DATE } = PRE_SELECTED;
  const { DAILY, WEEKLY } = MODE;
  const { MMDDYYYY } = FORMAT_DATE_TIME;

  const form = useForm({
    mode: 'onSubmit',
    shouldUnregister: true,
    defaultValues: {
      preSelectedMenuDate: menuDate,
      preSelectedMeal: choosenMeal,
      preSelectedView: choosenView,
      preSelectedStartDate: startDate,
      preSelectedEndDate: endDate,
    },
  });

  const { handleSubmit, setValue, control, getValues } = form;
  const preSelectedView = useWatch({
    control,
    name: DAILY_MEAL_VIEW,
  });

  const [selectRef, setSelectFocus] = useFocus<TCustomSelectForwardRef>();

  const dispatch = useAppDispatch<MenuAppDispatch>();

  const { formatMessage } = useIntl();
  const messages = {
    view: formatMessage({ id: 'View' }),
    daily: formatMessage({ id: 'Daily' }),
    weekly: formatMessage({ id: 'Weekly' }),
    meal: formatMessage({ id: 'Meal' }),
    dateMealFilterHeader: formatMessage({ id: 'DateMealFilterHeader' }),
    cancel: formatMessage({ id: 'Cancel' }),
    done: formatMessage({ id: 'Done' }),
    date: formatMessage({ id: 'Date' }),
  };

  const weeksOptions = useMemo(() => {
    return (
      weeks?.map((week) => ({
        value: week.startOfWeek,
        label: week.displayName,
        key: week.displayName,
      })) || []
    );
  }, [weeks]);

  const mealOptions: TMealOptions | [] = useMemo(() => {
    return (
      (!!MenuPeriods &&
        MenuPeriods.map((period) => {
          return { value: period.Name, label: period.Name, key: period.PeriodId };
        })) ||
      []
    );
  }, [MenuPeriods]);

  const HandleDoneClick = (): void => {
    const [
      preSelectedMenuDate,
      preSelectedMeal,
      preSelectedViewData,
      preSelectedStartDate,
      preSelectedEndDate,
    ] = getValues([
      'preSelectedMenuDate',
      'preSelectedMeal',
      'preSelectedView',
      'preSelectedStartDate',
      'preSelectedEndDate',
    ]);

    let dateString = '';
    if (preSelectedMenuDate && preSelectedViewData === DAILY) {
      dateString = preSelectedMenuDate;
    }
    if (preSelectedViewData === WEEKLY) {
      dateString = preSelectedStartDate;
    }
    const date = new Date(dateString);

    const callback = (): void => {
      if (preSelectedMenuDate !== menuDate) {
        dispatch(setDailyMenuDate(preSelectedMenuDate));
      }
      if (preSelectedMeal !== choosenMeal) {
        dispatch(setChoosenMeal(preSelectedMeal));
      }
      if (preSelectedViewData !== choosenView) {
        dispatch(setChoosenView(preSelectedViewData));
      }
      if (preSelectedStartDate !== startDate) {
        dispatch(setWeeklyMenuStartDate(preSelectedStartDate));
      }
      if (preSelectedEndDate !== endDate) {
        dispatch(setWeeklyMenuEndDate(preSelectedEndDate));
      }
      close();
    };
    dispatch(
      fetchMenu({
        mode: preSelectedViewData,
        customPeriodId: preSelectedMeal?.id,
        customDate: format(date, FORMAT_DATE_TIME.MMDDYYYY),
        callback,
      }),
    );
  };
  const HandleViewClick = (view: MODE): void => {
    setValue(DAILY_MEAL_VIEW, view);
  };

  const handleCanselClick = (): void => {
    close();
    setInputFocus();
  };

  const handleDoneClick = (): void => {
    HandleDoneClick();
    setInputFocus();
  };

  const datePickerCallback = (
    date: Date,
    onChange: (...event: unknown[]) => void,
    callback: VoidFunction,
  ): void => {
    const dateFormate = format(date, MMDDYYYY);
    const cb = (): void => {
      onChange(dateFormate);
      callback?.();
    };
    dispatch(GetMenuPeriods(StoreIds, dateFormate, preSelectedView, cb));
  };
  const weekCallback = (startOfWeek: string, callback?: VoidFunction): void => {
    const date = format(new Date(startOfWeek), MMDDYYYY);
    dispatch(GetMenuPeriods(StoreIds, date, preSelectedView, callback));
  };
  return (
    <ModalOne
      title={
        <span
          dangerouslySetInnerHTML={{
            __html: messages.dateMealFilterHeader,
          }}
        />
      }
      handlerClose={close}
      footer={
        <>
          <Button
            width="max-content"
            height="40px"
            padding="8px 24px"
            fontWeight="normal"
            borderRadius="4px"
            className="Cancel"
            click={handleCanselClick}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: messages.cancel,
              }}
            />
          </Button>
          <ButtonSecond isDark className="Done" click={handleSubmit(handleDoneClick)}>
            <span
              dangerouslySetInnerHTML={{
                __html: messages.done,
              }}
            />
          </ButtonSecond>
        </>
      }
      isFocusOnCloseButton={false}
    >
      <FormProvider {...form}>
        <ModalBodyWrapper className="ModalBodyWrapper">
          <WrapperContent className="WrapperDineInContent">
            {DisplayWeeklyMenu && DisplayDailyMenu && (
              <div>
                <ModalSubheader
                  dangerouslySetInnerHTML={{
                    __html: messages.view?.toUpperCase(),
                  }}
                />
                <div>
                  <ButtonOutline
                    tabIndex={0}
                    isActive={preSelectedView === DAILY}
                    onClick={() => HandleViewClick(DAILY)}
                    className="DineInButtonOutline"
                    dangerouslySetInnerHTML={{
                      __html: messages.daily,
                    }}
                  />
                  <ButtonOutline
                    tabIndex={0}
                    isActive={preSelectedView === WEEKLY}
                    onClick={() => {
                      HandleViewClick(WEEKLY);
                    }}
                    className="ButtonOutline"
                    dangerouslySetInnerHTML={{
                      __html: messages.weekly,
                    }}
                  />
                </div>
              </div>
            )}

            <ModalSubheader
              id="DateLabel"
              htmlFor="aria-date-controller"
              dangerouslySetInnerHTML={{
                __html:
                  preSelectedView === DAILY
                    ? messages.date?.toUpperCase()
                    : messages.weekly?.toUpperCase(),
              }}
            />
            <div>
              {preSelectedView === DAILY && <DailyBlock datePickerCallback={datePickerCallback} />}
              {preSelectedView === WEEKLY && (
                <Controller
                  control={control}
                  name={DAILY_MEAL_START_DATE}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    const defaultSelect =
                      weeksOptions.find((week) => week.value === value) ||
                      weeksOptions[0] ||
                      undefined;
                    return (
                      <Select
                        options={weeksOptions}
                        value={defaultSelect}
                        onChange={(newWeek: any) => {
                          const callback = (): void => {
                            onChange(newWeek.value);
                            setSelectFocus();
                          };
                          weekCallback(newWeek.value, callback);
                        }}
                        inputId="aria-date-controller"
                        aria-labelledby="DateLabel"
                        ref={selectRef}
                        isDisabled={DisplayOnlyCurrentDayMenu}
                      />
                    );
                  }}
                />
              )}
            </div>

            <MealFilter>
              <ModalSubheader
                id="MealLabel"
                htmlFor="aria-meal-input"
                dangerouslySetInnerHTML={{
                  __html: messages.meal?.toUpperCase(),
                }}
              />
              <Controller
                control={control}
                name={DAILY_MEAL_MEAL}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  const defaultSelect =
                    mealOptions.find((meal) => meal.value === value?.name) ||
                    mealOptions[0] ||
                    undefined;
                  return (
                    <Select
                      options={mealOptions}
                      value={defaultSelect}
                      onChange={(newValue) => {
                        onChange({ name: newValue?.value, id: newValue?.key });
                      }}
                      aria-labelledby="MealLabel"
                      inputId="aria-meal-input"
                    />
                  );
                }}
              />
            </MealFilter>
          </WrapperContent>
        </ModalBodyWrapper>
      </FormProvider>
    </ModalOne>
  );
};

export default DineInModal;
