import React from 'react';
import { useIntl } from 'react-intl';

import { FILTERS_TYPES } from '@/entities/menu';

import { IFilter, ITrackedFilters } from '../../../types';
import { WrapperFilters } from '../../parts';
import { ButtonActiveTag, CloseTag } from '../parts';

interface ITagProps {
  filter: IFilter;
  handler: VoidFunction;
}

const Tag: React.FC<ITagProps> = ({ filter: { Id, Name }, handler }) => {
  const { formatMessage } = useIntl();
  const messages = {
    tagAriaLabel: formatMessage({ id: 'Menus.Filters.Tags.Active.AriaLabel' }, { 0: Name }),
  };
  return (
    <ButtonActiveTag id={Id} onClick={handler} key={Id} aria-label={messages.tagAriaLabel}>
      {Name} <CloseTag id={Id}>✖</CloseTag>
    </ButtonActiveTag>
  );
};

interface ITagsProps {
  filters: ITrackedFilters;
  handlerClick: (id: string, type: string) => void;
}

const TagsActiveFilters: React.FC<ITagsProps> = ({
  filters: { allergens, specialDiets },
  handlerClick,
}) => {
  const { ALLERGENS, SPECIAL_DIETS } = FILTERS_TYPES;

  const tagsAllergens = allergens.map((filter) => {
    const handler = (): void => handlerClick(filter.Id, ALLERGENS);
    return <Tag key={filter.Id} filter={filter} handler={handler} />;
  });

  const tagsSpecialDiets = specialDiets.map((filter) => {
    const handler = (): void => handlerClick(filter.Id, SPECIAL_DIETS);
    return <Tag key={filter.Id} filter={filter} handler={handler} />;
  });

  const tags = [...tagsAllergens, ...tagsSpecialDiets];

  return <WrapperFilters>{tags.length > 0 && tags}</WrapperFilters>;
};

export default TagsActiveFilters;
