import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { DISPLAY_DEEMPHASIZED_TYPES } from '@/entities/menu';

import { PlusOrMinus } from '@/shared/components/Button';
import type { TMenuStation, TProducts } from '@/shared/types/menu';

// TODO: нужно вынести
import { CollapseDeemphasizedButton, MenuAddOns } from '../../CommonMenu/parts';
import { MenuItem } from './MenuItem';

interface IProps {
  featuredProducts?: TProducts;
  deemphasizedProducts?: TProducts;
  station: TMenuStation;
  toPrint: boolean;
}
export const MenuItems: React.FC<IProps> = ({
  featuredProducts,
  deemphasizedProducts,
  station,
  toPrint,
}) => {
  const { formatMessage } = useIntl();
  const messages = {
    seeLess: formatMessage({ id: 'SeeLess' }),
    seeMore: formatMessage({ id: 'SeeMore' }),
  };

  const initialCollapse =
    station == null ||
    (station.DisplayDeemphasizedType === DISPLAY_DEEMPHASIZED_TYPES.CollapseSelected &&
      station.CollapseDeemphasized) ||
    station.DisplayDeemphasizedType === DISPLAY_DEEMPHASIZED_TYPES.CollapseAll ||
    station.DisplayDeemphasizedType === DISPLAY_DEEMPHASIZED_TYPES.Hide;

  const [isCollapseDeemphasized, setCollapseDeemphasized] = useState(
    toPrint ? false : initialCollapse,
  );

  const collapseDeemphasizedTitle = isCollapseDeemphasized ? messages.seeMore : messages.seeLess;

  const showDeemphasizedButton =
    station?.DisplayDeemphasizedType !== DISPLAY_DEEMPHASIZED_TYPES.ExpandAll;

  return (
    <>
      {featuredProducts?.map((product) => (
        <MenuItem key={product.MenuProductId} product={product} toPrint={toPrint} />
      ))}
      {!toPrint &&
        showDeemphasizedButton &&
        deemphasizedProducts &&
        deemphasizedProducts.length > 0 && (
          <CollapseDeemphasizedButton
            click={() => {
              setCollapseDeemphasized(!isCollapseDeemphasized);
            }}
            aria-label={collapseDeemphasizedTitle}
            aria-expanded={!station.CollapseDeemphasized}
            aria-controls={`displayDeemphasized_${station.StationId}`}
            title={collapseDeemphasizedTitle}
            className="CollapseDeemphasizedButton"
            toPrint={toPrint}
          >
            <PlusOrMinus isPlus={isCollapseDeemphasized} />
            <span dangerouslySetInnerHTML={{ __html: collapseDeemphasizedTitle }} />
          </CollapseDeemphasizedButton>
        )}

      <MenuAddOns className="MenuAddOns" role="region" hide={isCollapseDeemphasized}>
        {deemphasizedProducts?.map((product) => (
          <MenuItem key={product.MenuProductId} product={product} toPrint={toPrint} />
        ))}
      </MenuAddOns>
    </>
  );
};
