import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { PrintWeeklyMenuBtn } from '../parts';

interface IProps {
  handlePrint: VoidFunction;
}

const WeeklyPrintBtn = forwardRef<HTMLButtonElement, IProps>(function PrintBtn(
  { handlePrint },
  ref,
) {
  const { formatMessage } = useIntl();

  const messages = {
    print: formatMessage({ id: 'PrintWeeklyMenu' }),
  };
  return (
    <PrintWeeklyMenuBtn onClick={handlePrint} aria-label={messages.print} ref={ref}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
        viewBox="0 0 24 24"
        focusable="false"
        aria-hidden="true"
      >
        <g>
          <path
            id="path1"
            transform="rotate(0,12,12) translate(0,0.00300121307373047) scale(0.75,0.75)  "
            fill="#3277B3"
            d="M8.0009928,24.949988L24.000992,24.949988 24.000992,25.949988 8.0009928,25.949988z M8.0009928,22.034003L24.000992,22.034003 24.000992,23.034003 8.0009928,23.034003z M8.0839996,18.991996L24.084,18.991996 24.084,19.991996 8.0839996,19.991996z M6.0009928,17.006983L6.0009928,29.991994 26.000992,29.991994 26.000992,23.011647 26,22.991996 26,17.006983z M2,6.9919958L2,21.991996 4,21.991996 4,15.991996C4,15.439017,4.447998,14.991996,5,14.991996L27,14.991996C27.517502,14.991996,27.943594,15.384886,27.994835,15.889638L27.995474,15.902311 27.995827,15.90462C27.999241,15.938271,28.000992,15.97242,28.000992,16.006982L28.000992,21.991996 30,21.991996 30,6.9919958z M6.0009928,1.9999976L6.0009928,4.983994 26.000992,4.983994 26.000992,1.9999976z M5.0009928,0L27.000992,0C27.552992,0,28.000992,0.44699955,28.000992,0.99999905L28.000992,4.9919958 31,4.9919958C31.552002,4.9919958,32,5.4390173,32,5.9919958L32,22.991996C32,23.544974,31.552002,23.991996,31,23.991996L28.000992,23.991996 28.000992,30.991996C28.000992,31.544995,27.552992,31.991996,27.000992,31.991996L5.0009928,31.991996C4.4479923,31.991996,4.0009928,31.544995,4.0009928,30.991996L4.0009928,23.991996 1,23.991996C0.44799805,23.991996,0,23.544974,0,22.991996L0,5.9919958C0,5.4390173,0.44799805,4.9919958,1,4.9919958L4.0009928,4.9919958 4.0009928,0.99999905C4.0009928,0.44699955,4.4479923,0,5.0009928,0z"
          />
        </g>
      </svg>
      <span
        style={{ marginLeft: '10px' }}
        dangerouslySetInnerHTML={{
          __html: messages.print,
        }}
      />
    </PrintWeeklyMenuBtn>
  );
});

export default WeeklyPrintBtn;
