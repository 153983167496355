import styled, { css } from 'styled-components';

import { ButtonLink } from '@/shared/components/Button';
import type { IMenuTheme } from '@/shared/themes/types';

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '767px',
};

export const CheckOutButton = styled(ButtonLink)`
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;

  &:focus {
    outline: black solid 2px;
    outline-offset: 0px;
  }
`;
export const CartButtonDiv = styled.div`
  ${({ theme, isShow }: { theme: IMenuTheme; isShow: boolean }) => css`
    display: ${isShow ? 'inline-flex' : 'none'};
    width: min-content;
    height: 40px;
    border-radius: 22.5px;
    background-color: ${theme.defaultPrimaryColor};
    border: 2px solid ${theme.defaultPrimaryColor};
    box-sizing: border-box;
    position: relative;
    top: 2px;
    flex-direction: row;
    margin-right: 1em;
    margin-top: 10px;

    @media (max-width: ${size.tablet}) {
      margin-bottom: 16px;
      margin-top: 0;
    }
  `};
`;

export const CartItemsText = styled.div`
  ${({ theme }: { theme: IMenuTheme }) => css`
    height: 40px;
    width: auto;
    color: #fff;
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.fontSizes.small};
    justify-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    margin-left: 8px;
    margin-right: 8px;
  `}
`;
