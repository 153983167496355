import styled, { css } from 'styled-components';

import { ProductCardComponent } from '@/shared/components/ProductCard/parts';
import type { IMenuTheme } from '@/shared/themes/types';

export const WrapperGoesWellWith = styled.div`
  ${({ theme }: { theme: IMenuTheme }) => css`
    display: grid;
    grid-gap: 31px;
    margin: 16px 10px 32px 10px;
    width: fit-content;

    ${theme.media.tablet} {
      min-width: 360px;
      margin: 16px auto 32px auto;
    }

    ${ProductCardComponent} {
      ${theme.media.tablet} {
        min-width: 360px;
      }
      ${theme.media.phone} {
        min-width: 318px;
      }
    }
  `}
`;

export const ButtonMealCalc = styled.button`
  border: 0;
  margin: 0 4px 0 0;
  padding: 0px;
  height: 24px;
  width: 24px;
  background: content-box;
  cursor: pointer;
`;
export const CalcMsgStyled = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.normal};
    font-weight: ${theme.fontWeight.medium};
  `};
`;
