import format from 'date-fns/format';

import { setChoosenMeal, setOrderMenuDate } from '@/entities/menu/filter';

import { getMenuCalendar, getMenuPeriods, getPickUPTimes } from '@/shared/api/menu';
import { FORMAT_DATE_TIME } from '@/shared/constants';
import { setLoading } from '@/shared/services/loading';
import { NOTIFICATIONS, NotificationService } from '@/shared/services/notification';
import { handleAvailableDates } from '@/shared/utils/handleAvailableDates';

import { setMealPeriods, setMenuCalendar, setSelectedPeriodId } from './slice';
import type {
  TActionGetMenuCalendar,
  TActionGetMenuPeriods,
  TActionGetPickUPTimes,
  TActionGetPickUPTimesForTabs,
} from './types';

export const INDEX_OF_TOMORROW_DATE = 1;

export const GetMenuCalendar: TActionGetMenuCalendar =
  (locationId, storeIds) => async (dispatch, getState) => {
    dispatch(setLoading(true));

    const OnlyAllowFutureDaysOnlineOrdering =
      !!getState().menu?.Menu?.model?.OnlyAllowFutureDaysOnlineOrdering;

    try {
      const response = await getMenuCalendar({ locationId, storeIds });
      const newData = handleAvailableDates(response);

      dispatch(setMenuCalendar(newData));

      if (newData?.AvailableDatesFoodOrder?.length > 1 && OnlyAllowFutureDaysOnlineOrdering) {
        const date = new Date(
          newData?.AvailableDatesFoodOrder[INDEX_OF_TOMORROW_DATE],
        ).toDateString();
        dispatch(setOrderMenuDate(date));
      }
    } catch (error) {
      NotificationService.open({
        status: NOTIFICATIONS.ERROR,
        text: (error as Error).message,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

export const GetMenuPeriods: TActionGetMenuPeriods =
  (storeId, date, mode, callback) => async (dispatch, getState) => {
    const { locationId } = getState().menu.requestParameters;
    dispatch(setLoading(true));

    try {
      const response = await getMenuPeriods({ locationId, storeId, date, mode });
      dispatch(setSelectedPeriodId(response.SelectedMealPeriodId));
      dispatch(
        setMealPeriods(
          response.Result.map((period) => {
            return { PeriodId: `${period.PeriodId}`, Name: period.PeriodName };
          }),
        ),
      );
      dispatch(
        setChoosenMeal({
          name:
            response.Result.find((period) => period.PeriodId === response.SelectedMealPeriodId)
              ?.PeriodName || null,
          id: response.SelectedMealPeriodId,
        }),
      );
      callback?.();
    } catch (error) {
      NotificationService.open({
        status: NOTIFICATIONS.ERROR,
        text: (error as Error).message,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

export const GetPickUPTimesForTabs: TActionGetPickUPTimesForTabs =
  ({ mode, periodId, fulfillmentMethod, callback }) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    const { locationId, pageStoreId, storeIds } = getState().menu.requestParameters;
    const OnlyAllowFutureDaysOnlineOrdering =
      !!getState().menu?.Menu?.model?.OnlyAllowFutureDaysOnlineOrdering;

    const {
      menu: { MenuCalendar },
      filters: { orderMenuDate },
    } = getState();
    const AvailableDatesFoodOrder = MenuCalendar?.AvailableDatesFoodOrder;
    let date: Nullable<string> = orderMenuDate;
    if (
      AvailableDatesFoodOrder &&
      AvailableDatesFoodOrder?.length > 1 &&
      OnlyAllowFutureDaysOnlineOrdering
    ) {
      date = format(
        new Date(AvailableDatesFoodOrder[INDEX_OF_TOMORROW_DATE]),
        FORMAT_DATE_TIME.MMDDYYYY,
      );
    }

    try {
      const fulfillment = typeof fulfillmentMethod === 'number' ? fulfillmentMethod : null;
      const response = await getPickUPTimes({
        locationId,
        storeIds,
        date,
        fulfillment,
        mode,
        pageStoreId,
        periodId,
      });
      if (callback) callback(response.Result);
    } catch (error) {
      NotificationService.open({
        status: NOTIFICATIONS.ERROR,
        text: (error as Error).message,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

export const GetPickUPTimes: TActionGetPickUPTimes =
  ({ mode, date, periodId, fulfillmentMethod, callback, handleTurnOffLoading }) =>
  async (_, getState) => {
    // TODO: проверить что все работает без доп диспачей
    const { locationId, pageStoreId, storeIds } = getState().menu.requestParameters;
    try {
      const fulfillment = typeof fulfillmentMethod === 'number' ? fulfillmentMethod : null;
      const response = await getPickUPTimes({
        locationId,
        storeIds,
        date,
        fulfillment,
        mode,
        pageStoreId,
        periodId,
      });

      if (callback) callback(response.Result);
    } catch (error) {
      NotificationService.open({
        status: NOTIFICATIONS.ERROR,
        text: (error as Error).message,
      });
    } finally {
      handleTurnOffLoading();
    }
  };
