import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { getFulfillment, getMenu, isHideProductsShortDescription } from '@/entities/menu';
import { AddFoodProductToCart } from '@/entities/menu/order';

import { ProductCard } from '@/shared/components/ProductCard';
import { ModalProduct, Nutrition } from '@/shared/components/ProductDetails';
import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';
import { ModalService } from '@/shared/services/modal';
import type { TProduct } from '@/shared/types/menu';
import { hasValue } from '@/shared/utils/validation';

import {
  AddOrRemoveFromFavorite,
  FoodProductDetails,
  getFavoriteProductIds,
  getTotalMealIds,
} from '../../model';
import { ButtonMealCalculator } from './blocks/ButtonMealCalculator';
import { MealCalcNutrition } from './blocks/MealCalcNutrition';
import { AddFavorite } from './modals/AddFavorite';

interface IProps {
  product: TProduct;
  margin?: string;
}
const ProductTile: React.FC<IProps> = ({ product, margin }) => {
  const menuModel = useAppSelector(getMenu)!.model;
  const isProductInTotalMeal = useAppSelector(getTotalMealIds).includes(product?.MenuProductId);
  const isFavorite = useAppSelector(getFavoriteProductIds).includes(product?.MenuProductId);
  const hideProductsShortDescription = useAppSelector(isHideProductsShortDescription);
  const fulfillment = useAppSelector(getFulfillment);
  const { formatMessage } = useIntl();

  const messages = {
    addCart: formatMessage({ id: 'AddCart' }),
  };
  const dispatch = useAppDispatch<MenuAppDispatch>();

  // TODO: подумать о переносе
  const isProductCalories = !menuModel.HideProductsCalorie && hasValue(product?.Product?.Calories);
  // TODO: подумать о переносе
  const isOpenNutritionalInformation =
    !menuModel.HideProductsNutritional &&
    !product?.Product?.IsBulkProduct &&
    product?.Product?.HasNutritionalInformation &&
    isProductCalories;

  const canSelectProduct =
    menuModel.OnlyAllowFutureDaysOnlineOrdering && !menuModel.OnlyShowCurrentDaysOnlineOrdering
      ? menuModel.IsCheckoutAndCartEnabled &&
        !!menuModel.Date &&
        new Date(menuModel.Date) > new Date()
      : menuModel.IsCheckoutAndCartEnabled;

  const isOrderAllowed =
    menuModel.IsFoodOrder && canSelectProduct && product?.HasDeliverySlotInSchedule;

  const handleAddToFavorites = useCallback((): void => {
    dispatch(
      AddOrRemoveFromFavorite({
        productId: product.ProductId,
        catalogId: product.CatalogId,
        storeIds: '',
        isRemoving: isFavorite,
        MenuProductId: product.MenuProductId,
        selectedPeriodId: menuModel.SelectedPeriodId || '',
        customDate: menuModel.Date || '',
        callback: (data) => {
          if (!isFavorite && data && data.unauthorized)
            ModalService.open(AddFavorite, {
              marketingName: product?.Product?.MarketingName,
            });
        },
      }),
    );
  }, [
    dispatch,
    isFavorite,
    menuModel.Date,
    menuModel.SelectedPeriodId,
    product.CatalogId,
    product.MenuProductId,
    product?.Product?.MarketingName,
    product.ProductId,
  ]);

  const handleClickAddToCart = useCallback(
    (updateData, close, setShowWarningMsg): void => {
      dispatch(AddFoodProductToCart(updateData, close, setShowWarningMsg));
      if (window?.gaGtag) {
        const productDataForEvent = {
          item_id: product?.Product?.ProductId,
          item_name: product?.Product?.MarketingName || product?.Product?.DisplayName,
          item_category: product?.Product?.Categories?.[0].CategoryId ?? '',
          price: product?.Product?.CurrentPrice,
          quantity: updateData.UserOrderingInfo.SelectedProductQuantity,
        };
        window.gaGtag.addProductToCart(productDataForEvent);
        window.gaGtag.uxClickEvent('add to cart');
      }
    },
    [
      dispatch,
      product?.Product?.Categories,
      product?.Product?.CurrentPrice,
      product?.Product?.DisplayName,
      product?.Product?.MarketingName,
      product?.Product?.ProductId,
    ],
  );

  const handleOpenFoodProductDetails = useCallback((): void => {
    dispatch(
      FoodProductDetails(
        {
          prod: product?.ProductId,
          root: product?.CatalogId,
        },
        (productDetailsWithModifiers) =>
          ModalService.open(ModalProduct, {
            product: product.Product,
            productDetailsWithModifiers,
            isOrderAllowed,
            isOpenNutritionalInformation,
            hideProductsShortDescription,
            fulfillment,
            handlerFooter: handleClickAddToCart,
            nutritionComponent: (
              <Nutrition
                productInfo={product.Product}
                calcComponent={
                  <MealCalcNutrition
                    menuProductId={product.MenuProductId}
                    marketingName={product.Product.MarketingName}
                  />
                }
              />
            ),
            allowGA: true,
            nameButton: messages.addCart,
            shouldReturnFocusAfterClose: false,
          }),
      ),
    );
  }, [
    dispatch,
    fulfillment,
    handleClickAddToCart,
    hideProductsShortDescription,
    isOpenNutritionalInformation,
    isOrderAllowed,
    messages.addCart,
    product?.CatalogId,
    product.MenuProductId,
    product.Product,
    product?.ProductId,
  ]);

  return (
    <ProductCard
      product={product}
      model={menuModel}
      context={{
        isProductInTotalMeal,
        isFavorite,
        handleOpenFoodProductDetails,
        handleAddToFavorites,
        renderButtonMealCalculator: (
          <ButtonMealCalculator
            menuProductId={product.MenuProductId}
            marketingName={product.Product.MarketingName}
          />
        ),
        margin,
      }}
    />
  );
};

export default ProductTile;
