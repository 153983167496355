import React from 'react';

import { IFilter } from '../../../types';
import { ButtonOutline } from '../../parts';

interface IProps {
  IsActive: IFilter['IsActive'];
  Id: IFilter['Id'];
  Name: IFilter['Name'];
  onClick: (id: string, type?: string) => void;
  type?: string;
}

const FilterItem: React.FC<IProps> = ({ IsActive, Id, Name, onClick, type }) => {
  const handleOnClick = (): void => {
    onClick(Id, type);
  };

  return (
    <ButtonOutline tabIndex={0} aria-pressed={IsActive} isActive={IsActive} onClick={handleOnClick}>
      {Name}
    </ButtonOutline>
  );
};

export default FilterItem;
