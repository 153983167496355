import { fetchMenu } from '@/shared/api/menu';
import type { TProducts } from '@/shared/types/menu';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { IProductsSlice, TTotalmeal } from './types';

const initialState: IProductsSlice = {
  list: [],
  TotalMeal: null,
  TotalMealIds: [],
  FavoriteProductIds: [],
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<TProducts>) => {
      return {
        ...state,
        list: action.payload,
      };
    },
    setFavoriteProducts: (
      state,
      action: PayloadAction<{
        userFavoritiesEnabled: boolean;
        favoritesIds: string[];
      }>,
    ) => {
      const { userFavoritiesEnabled, favoritesIds } = action.payload;
      if (!userFavoritiesEnabled) return { ...state, FavoriteProducts: [] };
      return {
        ...state,
        FavoriteProductIds: favoritesIds,
      };
    },
    setTotalMealIds: (
      state,
      action: PayloadAction<
        Nullable<{
          MenuProductId: string;
        }>
      >,
    ) => {
      if (action.payload === null) {
        return {
          ...state,
          TotalMealIds: [],
        };
      }
      const { MenuProductId } = action.payload;
      const total = [...state.TotalMealIds];
      const ind = total.indexOf(MenuProductId);

      if (ind > -1) total.splice(ind, 1);
      else total.push(MenuProductId);

      return {
        ...state,
        TotalMealIds: total,
      };
    },
    setTotalMeal: (state, action: PayloadAction<Nullable<TTotalmeal>>) => {
      return {
        ...state,
        TotalMeal: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenu.fulfilled, (state, { payload: { menu } }) => {
      state.list = menu.Menu.MenuProducts;

      if (menu.UserFavoritiesEnabled) {
        state.FavoriteProductIds = menu.Menu.MenuProducts.filter(
          (item) => item?.IsUserFavorite,
        ).map((item) => item?.MenuProductId);
      }
    });
  },
});
export const { setProducts, setTotalMeal, setTotalMealIds, setFavoriteProducts } =
  productsSlice.actions;
export default productsSlice.reducer;
