import styled from 'styled-components';

export const NavigationCustom = styled.nav`
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #fff;
  height: max-content;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;
`;

export const OverflowNavWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
`;

export const Gear = styled.svg`
  width: 24px;
  height: 24px;
  justify-content: center;
  margin-top: 14%;
  margin-left: 28%;
  path {
    fill: ${({ theme }) => theme.defaultPrimaryColor};
  }
`;

export const CircleDiv = styled.div`
  border-radius: 22.5px;
  width: 56px;
  height: 40px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
  margin-top: 16px;
  background-color: #f7f7f7;
  border: 1px;
  z-index: 99;
  position: absolute;
`;
