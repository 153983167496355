import React, { PropsWithChildren } from 'react';

import { ModalSubheader } from '../../parts';
import { FilterItemsInModal } from '../parts';

interface IProps {
  htmlText: string;
}

const FilterItems: React.FC<PropsWithChildren<IProps>> = ({ children, htmlText }) => {
  return (
    <FilterItemsInModal>
      <ModalSubheader
        id="modalDescription"
        dangerouslySetInnerHTML={{
          __html: htmlText,
        }}
      />{' '}
      <div>{children}</div>
    </FilterItemsInModal>
  );
};

export default FilterItems;
