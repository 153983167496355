import styled, { css } from 'styled-components';

import { ButtonText } from '@/shared/components/Button';
import type { IMenuTheme } from '@/shared/themes/types';

export const WeeklyMenuItem = styled.div`
  margin: 1em 1.5%;
  border: 1px solid;
  border-radius: 4px;
  width: 100%;
  @media (min-width: 486px) {
    width: 47%;
  }
  @media (min-width: 768px) {
    width: 30%;
  }
`;

export const WeeklyMenuItemHeader = styled.h2`
  font-size: 1.5em;
  line-height: 2em;
  margin: 0;
  text-align: center;
  background: #ededed;
  color: #666;
  font-weight: 600;
`;

export const WeeklyMenuItemPrice = styled.span`
  float: right;
  font-size: ${({ toPrint }: { toPrint: boolean }) => (toPrint ? '8px' : 'unset')};
  line-height: ${({ toPrint }: { toPrint: boolean }) => (toPrint ? 'normal' : 'inherit')};
`;

export const WeeklyMenuItemStations = styled.div`
  padding: ${({ toPrint }: { toPrint: boolean }) => (toPrint ? 'unset' : '1em')};
`;

export const WeeklyMenuItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const PrintWeeklyMenuBtn = styled.button`
  outline-style: solid;
  outline-color: #3277b3;
  padding: 0.25em 0.5em 0.5em;
  background: none;
  border: none;
  margin: 0;
  outline: none;

  &:focus {
    border: 2px solid;
  }
`;
export const HeaderItemTopWeeklyWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: ${({ toPrint }: { toPrint: boolean }) => (toPrint ? '8px' : '16px')};
`;
export const HeaderItemNameLinkWeeklyMenuButton = styled(ButtonText)`
  ${({ theme, toPrint }: { theme: IMenuTheme; toPrint: boolean }) => css`
    width: fit-content;
    font-weight: ${toPrint ? 'normal' : 'unset'};
    padding: 0;
    display: inline-flex;
    height: auto;
    max-height: 40px;
    color: ${theme.defaultPrimaryColor};
    overflow: hidden;
    font-size: ${toPrint ? '8px' : 'unset'};
  `}
`;

export const HeaderItemNameLinkWeeklyMenuText = styled.div`
  ${({ toPrint }: { toPrint: boolean }) => css`
    width: fit-content;
    display: inline-block;
    font-size: ${toPrint ? '8px' : 'unset'};
    line-height: 1.3;
  `}
`;
