import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { WRAPPER_TABS, getIsAvailableDatesFoodOrder } from '@/entities/menu';
import {
  getChoosenView,
  getDailyMenuDate,
  setChoosenMeal,
  setDailyMenuDate,
  setOrderMenuDate,
} from '@/entities/menu/filter';
import { getIsProducts } from '@/entities/menu/product';

import { fetchMenu } from '@/shared/api/menu';
import { Tabs as WrapperTabs } from '@/shared/components/Tabs';
import type { ITabsProps } from '@/shared/components/Tabs/types';
import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';
import type { TPeriod } from '@/shared/types/menu';

interface IProps {
  handlerPickUPTimesModeFoodOrder: (lastIndex: number) => void;
  tabIndex: number;
  setTabIndex: (value: number) => void;
  IsFoodOrder: boolean;
  DisplayDailyMenu: boolean;
  DisplayFoodOrderMenu: boolean;
  DisplayWeeklyMenu: boolean;
  defaultDayInPeriod: TPeriod;
}
enum DATA_TABS {
  DAYIN_ID = 'dineIn',
  ORDER_AHEAD_ID = 'orderAhead',
}
export const Tabs: React.FC<IProps> = ({
  handlerPickUPTimesModeFoodOrder,
  tabIndex,
  setTabIndex,
  IsFoodOrder,
  DisplayDailyMenu,
  DisplayFoodOrderMenu,
  DisplayWeeklyMenu,
  defaultDayInPeriod,
}) => {
  const isProducts = useAppSelector(getIsProducts);
  const isMenuCalendar = useAppSelector(getIsAvailableDatesFoodOrder);
  const menuDate = useAppSelector(getDailyMenuDate);
  const dispatch = useAppDispatch<MenuAppDispatch>();
  const handlerChoosenMeal = (period: TPeriod): void => {
    dispatch(
      setChoosenMeal({
        name: period.name,
        id: period.periodId ? Number(period.periodId) : null,
      }),
    );
  };
  const handlerMenuDate = (value: string): void => {
    dispatch(setDailyMenuDate(value));
  };
  const orderMenuDate = (value: Nullable<string>): void => {
    dispatch(setOrderMenuDate(value));
  };
  const choosenView = useAppSelector(getChoosenView);

  const { formatMessage } = useIntl();

  const messages = {
    DineIn: formatMessage({ id: 'DineIn' }),
    OrderAhead: formatMessage({ id: 'OrderAhead' }),
  };

  const changeMenus = (index: number, lastIndex: number, idTab?: DATA_TABS): void => {
    if (idTab === DATA_TABS.DAYIN_ID) {
      dispatch(fetchMenu({ mode: choosenView, callback: () => setTabIndex(index) }));

      handlerChoosenMeal(defaultDayInPeriod);
      handlerMenuDate(new Date().toDateString());
    }

    if (idTab === DATA_TABS.ORDER_AHEAD_ID) {
      orderMenuDate(menuDate);
      handlerPickUPTimesModeFoodOrder(lastIndex);
    }
  };

  useEffect(() => {
    // Called once, after loading the calendar menu.
    if (isMenuCalendar && IsFoodOrder && !isProducts) {
      handlerPickUPTimesModeFoodOrder(tabIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuCalendar]);

  const tabsOptions: ITabsProps = {
    id: WRAPPER_TABS,
    selectedIndex: tabIndex,
    onSelect: (index: number, lastIndex: number, event: Event) => {
      const idTab = (event.target as HTMLDivElement).dataset.id as DATA_TABS;
      changeMenus(index, lastIndex, idTab);
    },
    isNoContent: true,
    listTabs: [
      {
        id: DATA_TABS.DAYIN_ID,
        name: (
          <span
            data-id={DATA_TABS.DAYIN_ID}
            dangerouslySetInnerHTML={{
              __html: messages.DineIn,
            }}
          />
        ),
        ariaLabel: messages.DineIn,
        visible: DisplayFoodOrderMenu && (DisplayDailyMenu || DisplayWeeklyMenu),
        panel: null,
        disabled: tabIndex === 0,
      },
      {
        id: DATA_TABS.ORDER_AHEAD_ID,
        name: (
          <span
            data-id={DATA_TABS.ORDER_AHEAD_ID}
            dangerouslySetInnerHTML={{
              __html: messages.OrderAhead,
            }}
          />
        ),
        ariaLabel: messages.OrderAhead,
        visible: DisplayFoodOrderMenu && (DisplayDailyMenu || DisplayWeeklyMenu),
        panel: null,
        disabled: tabIndex === 1,
      },
    ],
  };

  return <WrapperTabs {...tabsOptions} />;
};
