import format from 'date-fns/format';
import React, { ForwardRefRenderFunction, forwardRef, useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { PRE_SELECTED, getMenuCalendar } from '@/entities/menu';

import { DatePicker, DatePickerButton, useDatePicker } from '@/shared/components/DatePicker';
import { FORMAT_DATE_TIME } from '@/shared/constants';
import { useAppSelector } from '@/shared/hooks/appRedux';

interface IProps {
  datePickerCallback: (
    date: Date,
    onChange: (...event: unknown[]) => void,
    callback: VoidFunction,
  ) => void;
}

const DailyBlock: React.FC<IProps> = ({ datePickerCallback }) => {
  const MenuCalendar = useAppSelector(getMenuCalendar);

  const { control, watch } = useFormContext();

  const datePickerProps = useDatePicker({
    availableDates: MenuCalendar?.AvailableDates,
    numberDaysAfter: MenuCalendar?.NumberDaysAfter,
  });

  const { DAILY_MEAL_MENU_DATE } = PRE_SELECTED;

  const { [DAILY_MEAL_MENU_DATE]: preSelectedMenuDate } = watch();

  const selectedDate = useMemo(() => {
    if (preSelectedMenuDate) return preSelectedMenuDate;

    if (datePickerProps.availableDates && datePickerProps.availableDates?.length > 0) {
      return format(new Date(datePickerProps.availableDates[0]), FORMAT_DATE_TIME.YYYYMMDDTHHmmss);
    }

    return format(new Date(), FORMAT_DATE_TIME.YYYYMMDDTHHmmss);
  }, [datePickerProps.availableDates, preSelectedMenuDate]);

  const Button = useCallback<
    ForwardRefRenderFunction<HTMLButtonElement, { disabled?: boolean; value?: string }>
  >(
    ({ disabled, value }, ref) => {
      return (
        <DatePickerButton
          value={value}
          disabled={disabled}
          isOpen={datePickerProps.isOpenDatePicker}
          setOpen={datePickerProps.setOpenDatePicker}
          customInputRef={ref}
        />
      );
    },
    [datePickerProps.isOpenDatePicker, datePickerProps.setOpenDatePicker],
  );

  const CustomInput = forwardRef(Button);

  return (
    <Controller
      control={control}
      name={DAILY_MEAL_MENU_DATE}
      rules={{ required: true }}
      defaultValue={selectedDate}
      render={({ field: { onChange, value } }) => {
        const handlerDatePicker = (date: Date, callback: VoidFunction): void => {
          const handlerCallback = (): void => {
            callback();
            datePickerProps.setOpenDatePicker(false);
          };
          datePickerCallback(date, onChange, handlerCallback);
        };
        return (
          <DatePicker
            callback={handlerDatePicker}
            preSelectedMenuDate={value}
            customInput={<CustomInput />}
            {...datePickerProps}
          />
        );
      }}
    />
  );
};

export default DailyBlock;
