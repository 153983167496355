import { isSameDay } from 'date-fns/fp';
import sortBy from 'lodash/sortBy';
import React, { ReactElement, useCallback } from 'react';

import {
  getCategories,
  getIsShowCategories,
  getMenuStations,
  getSelectedPeriodId,
} from '@/entities/menu';

import { useAppSelector } from '@/shared/hooks/appRedux';
import type { TMenuStation, TProducts } from '@/shared/types/menu';

// TODO: нужно вынести
import { CategoryName } from '../../CommonMenu/parts';
import { WeeklyMenuItemStations } from '../parts';
import { MenuItems } from './MenuItems';
import { WeeklyMenuItemStation } from './WeeklyMenuItemStation';

interface IProps {
  products: TProducts;
  day: {
    date: number;
    name: string;
  };
  toPrint: boolean;
}
export const WeeklyMenuItemBody: React.FC<IProps> = ({ products, day, toPrint }) => {
  const menuStations = useAppSelector(getMenuStations);
  const selectedPeriodId = useAppSelector(getSelectedPeriodId);
  const menuCategories = useAppSelector(getCategories);
  const IsShowMenuCategories = useAppSelector(getIsShowCategories);
  const filterMenuStation = menuStations.filter((station) => station.PeriodId === selectedPeriodId);
  const stations = sortBy<TMenuStation>(filterMenuStation, ['StationRank']);

  const multipleStationsExist = stations.length > 1;

  // TODO: присылать с бэка даты в локальном виде и в правильном формате
  const filterProductsByStationAndDate = useCallback(
    ({
      dayOfMonth,
      station,
    }: {
      dayOfMonth: {
        date: number;
        name: string;
      };
      station: TMenuStation;
    }) => {
      return products?.filter(
        (product) =>
          product.StationId === station.StationId &&
          product.AssignedDate &&
          isSameDay(new Date(product.AssignedDate), new Date(dayOfMonth.date)),
      );
    },
    [products],
  );
  const stationHeaders: {
    stationId: string;
    station: TMenuStation;
    element: ReactElement | null;
  }[] = [];
  const categories: {
    displayName: Nullable<string>;
    stationId: string;
    featuredProducts: TProducts;
    deemphasizedProducts: TProducts;
    element: JSX.Element;
  }[] = [];
  const stationProducts: {
    stationId: string;
    featuredProducts?: TProducts;
    deemphasizedProducts?: TProducts;
  }[] = [];

  stations.forEach((station) => {
    const productsByStationAndDate = filterProductsByStationAndDate({ dayOfMonth: day, station });
    if (productsByStationAndDate?.length > 0) {
      if (IsShowMenuCategories) {
        if (multipleStationsExist) {
          stationHeaders.push({
            stationId: station.StationId,
            station,
            element: <WeeklyMenuItemStation station={station} toPrint={toPrint} />,
          });
        }
        menuCategories?.forEach((category) => {
          const productsByCategory = productsByStationAndDate.filter(
            (product) =>
              product.Product.Categories &&
              product.Product.Categories.findIndex(
                (cat) => cat.CategoryId === category.CategoryId,
              ) >= 0,
          );

          const deemphasizedProducts = productsByCategory.filter(
            (product) => product.IsDeemphasized,
          );
          const featuredProducts = productsByCategory.filter((product) => !product.IsDeemphasized);
          if (deemphasizedProducts.length > 0 || featuredProducts.length > 0) {
            if (
              categories.findIndex(
                (cat) =>
                  cat.displayName === category.DisplayName && cat.stationId === station.StationId,
              ) < 0
            ) {
              categories.push({
                displayName: category.DisplayName,
                stationId: station.StationId,
                featuredProducts,
                deemphasizedProducts,
                element: (
                  <CategoryName className="CategoryName" toPrint={toPrint}>
                    {category.DisplayName}
                  </CategoryName>
                ),
              });
            }
          }
        });
      } else {
        const deemphasizedProducts = productsByStationAndDate.filter(
          (product) => product.IsDeemphasized,
        );
        const featuredProducts = productsByStationAndDate.filter((product) => {
          return !product.IsDeemphasized;
        });

        if (multipleStationsExist) {
          stationHeaders.push({
            stationId: station.StationId,
            station,
            element: <WeeklyMenuItemStation station={station} toPrint={toPrint} />,
          });
        }

        stationProducts.push({
          stationId: station.StationId,
          featuredProducts,
          deemphasizedProducts,
        });
      }
    }
  });

  return (
    <>
      {stations.map(
        (station) =>
          (categories.filter((category) => category.stationId === station.StationId).length > 0 ||
            stationProducts.filter(
              (stationProduct) => station.StationId === stationProduct.stationId,
            ).length > 0) && (
            <WeeklyMenuItemStations toPrint={toPrint} key={station.StationId + station.Name}>
              {multipleStationsExist &&
                stationHeaders?.find((sh) => sh.stationId === station.StationId)?.element}
              {categories.map(
                (category) =>
                  category.stationId === station.StationId && (
                    <div key={category.stationId + category.displayName}>
                      {category.element}{' '}
                      <MenuItems
                        featuredProducts={category.featuredProducts}
                        deemphasizedProducts={category.deemphasizedProducts}
                        station={station}
                        toPrint={toPrint}
                      />
                    </div>
                  ),
              )}
              {stationProducts
                .filter((stationProduct) => stationProduct.stationId === station.StationId)
                .map((stationProduct) => {
                  return (
                    <MenuItems
                      key={stationProduct.stationId}
                      featuredProducts={stationProduct.featuredProducts}
                      deemphasizedProducts={stationProduct.deemphasizedProducts}
                      station={station}
                      toPrint={toPrint}
                    />
                  );
                })}
            </WeeklyMenuItemStations>
          ),
      )}
    </>
  );
};
