import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { getLinkToLoginPageWithReturnUrl } from '@/entities/menu';

import { ButtonLink, ButtonSecond } from '@/shared/components/Button';
import { useAppSelector } from '@/shared/hooks/appRedux';
import { ModalOne } from '@/shared/services/modal';
import type { TPortalModalComponent } from '@/shared/services/modal/type';

interface IProps {
  marketingName: Nullable<string>;
}
const Content = styled.div`
  margin: 8px 32px 14px;
  font-size: 16px;
  color: #000;
`;

export const AddFavorite: TPortalModalComponent<IProps> = ({ marketingName, close }) => {
  const linkToLoginPageWithReturnUrl = useAppSelector(getLinkToLoginPageWithReturnUrl);
  const { formatMessage } = useIntl();

  const messages = {
    addToFavoriteModalBody: formatMessage({ id: 'AddToFavoriteModalBody' }),
    skip: formatMessage({ id: 'Skip' }),
    signIn: formatMessage({ id: 'SignIn' }),
    added: formatMessage({ id: 'Added' }),
  };

  const footer = (
    <>
      <ButtonSecond isDark height="40px" padding="8px 24px" margin="0" click={close}>
        <span dangerouslySetInnerHTML={{ __html: messages.skip }} />
      </ButtonSecond>
      <ButtonLink isDark padding="8px 24px" margin="0" link={linkToLoginPageWithReturnUrl}>
        <span dangerouslySetInnerHTML={{ __html: messages.signIn }} />
      </ButtonLink>
    </>
  );
  return (
    <ModalOne
      title={
        <span
          dangerouslySetInnerHTML={{
            __html: `${marketingName} ${messages.added}`,
          }}
        />
      }
      handlerClose={close}
      footer={footer}
      isFocusOnCloseButton
    >
      <Content
        dangerouslySetInnerHTML={{
          __html: messages.addToFavoriteModalBody,
        }}
      />
    </ModalOne>
  );
};
