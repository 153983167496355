import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { toQuery, useMediaQuery } from 'react-responsive';

import { FILTERS_TYPES, getMenu } from '@/entities/menu';
import { SaveFilters, getFilters } from '@/entities/menu/filter';

import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';
import { ModalService } from '@/shared/services/modal';
import { srSpeak } from '@/shared/utils/srSpeak';

import type { ITrackedFilters, TAllergens, TFilters, TSpecialDiets } from '../../types';
import ButtonFilter from './blocks/ButtonFilter';
import TagsActiveFilters from './blocks/TagsActiveFilters';
import ModalFilters from './modal/ModalFilters';

interface IModel {
  model: {
    Menu: {
      Allergens: TAllergens;
      SpecialDiets: TSpecialDiets;
      DisplayFoodPreferenceFilter: boolean;
      ImportPrimaIngredients: boolean;
    };
    EnableAllergenFiltering: boolean;
    SpecialDietsTitle: string;
    AllergensTitle: string;
  };
}

const Filters: React.FC<PropsWithChildren> = ({ children }) => {
  const { ALLERGENS } = FILTERS_TYPES;
  const {
    model: {
      Menu: { DisplayFoodPreferenceFilter, ImportPrimaIngredients },
      EnableAllergenFiltering,
      SpecialDietsTitle,
      AllergensTitle,
    },
  } = useAppSelector(getMenu) as IModel;

  const trackedFilters = useAppSelector(getFilters) as ITrackedFilters;
  const { allergens, specialDiets } = trackedFilters;

  const dispatch = useAppDispatch<MenuAppDispatch>();

  const { formatMessage } = useIntl();
  const messages = {
    allergernsFilterCaption: formatMessage({ id: 'allergernsFilterCaption' }),
    selections: formatMessage({ id: 'Selections' }),
    selectDiets: formatMessage({ id: 'SelectDiets' }),
    filterAllergensDiets: formatMessage({ id: 'FilterAllergensDiets' }),
    menuIsFiltered: formatMessage({ id: 'MenuIsFiltered' }),
  };

  const isMobile = useMediaQuery({
    query: toQuery({
      maxWidth: 767,
    }),
  });

  const isShowAllergens = EnableAllergenFiltering && allergens?.length > 0;
  const isShowSpecialDiets =
    DisplayFoodPreferenceFilter && ImportPrimaIngredients && specialDiets?.length > 0;
  const isShowMobileVersion = isMobile && (isShowAllergens || isShowSpecialDiets);

  const activeAllegens = allergens.filter((filter) => filter.IsActive);
  const activeSpecialDiets = specialDiets.filter((filter) => filter.IsActive);

  const countActiveAllegens = activeAllegens.length;
  const countActiveSpecialDiets = activeSpecialDiets.length;
  const countActiveAllFilters = countActiveAllegens + countActiveSpecialDiets;

  const handlerSave = (filters: ITrackedFilters): void => {
    dispatch(SaveFilters(filters));
    srSpeak(messages?.menuIsFiltered);
  };

  const handlerSaveAllergens = (allergenFilters: TAllergens): void => {
    const newFilters = { allergens: allergenFilters, specialDiets };
    handlerSave(newFilters);
  };

  const handlerSaveSpecialDiets = (specialDietsFilters: TSpecialDiets): void => {
    const newFilters = { specialDiets: specialDietsFilters, allergens };
    handlerSave(newFilters);
  };

  const handlerDisableFilter = (filters: TFilters, id: string): TFilters =>
    filters.map((filter) => {
      if (filter.Id === id) return { ...filter, IsActive: false };
      return filter;
    });

  const handlerRemoveTags = (id: string, type: string): void => {
    let newAllegens = allergens.slice();
    let newSpecialDiets = specialDiets.slice();

    if (type === ALLERGENS) {
      newAllegens = handlerDisableFilter(newAllegens, id);
    } else {
      newSpecialDiets = handlerDisableFilter(newSpecialDiets, id);
    }
    const newFilters = { specialDiets: newSpecialDiets, allergens: newAllegens };
    handlerSave(newFilters);
  };

  const handlerOpenModal = (options: {
    title: string;
    setFocus: VoidFunction;
    handleSaveClick: (filters: ITrackedFilters | TFilters) => void;
    trackedFilters: ITrackedFilters | TFilters;
    isAllergens?: boolean;
  }): void => {
    ModalService.open(ModalFilters, { ...options, ariaDescribedby: 'modalDescription' });
  };

  const handlerAllergenButtonClick = (setFocus: VoidFunction): void => {
    const options = {
      title: AllergensTitle,
      setFocus,
      handleSaveClick: handlerSaveAllergens,
      trackedFilters: allergens,
      isAllergens: true,
    };
    // TODO: rewrite
    // @ts-expect-error: rewrite
    handlerOpenModal(options);
  };

  const handlerSpecialDietsButtonClick = (setFocus: VoidFunction): void => {
    const options = {
      title: SpecialDietsTitle,
      setFocus,
      handleSaveClick: handlerSaveSpecialDiets,
      trackedFilters: specialDiets,
    };
    // TODO: rewrite
    // @ts-expect-error: rewrite
    handlerOpenModal(options);
  };

  const handlerMobileButtonClick = (setFocus: VoidFunction): void => {
    let title = '';

    if (isShowAllergens && isShowSpecialDiets) {
      title = `${AllergensTitle}/${SpecialDietsTitle}`;
    } else if (isShowAllergens) {
      title = AllergensTitle;
    } else {
      title = SpecialDietsTitle;
    }

    const options = {
      title,
      setFocus,
      handleSaveClick: handlerSave,
      trackedFilters,
    };
    // TODO: rewrite
    // @ts-expect-error: rewrite
    handlerOpenModal(options);
  };

  return (
    <>
      {isShowMobileVersion && (
        <ButtonFilter
          title=""
          onClick={handlerMobileButtonClick}
          countSelected={countActiveAllFilters}
          ariaDescription={messages.filterAllergensDiets}
          isMobile
          idSrOnly="GeneralFiltersForMobile"
        />
      )}
      {!isMobile && isShowAllergens && (
        <ButtonFilter
          title={AllergensTitle}
          onClick={handlerAllergenButtonClick}
          countSelected={countActiveAllegens}
          ariaDescription={messages.allergernsFilterCaption}
          idSrOnly="AllergernsFilters"
        />
      )}
      {!isMobile && isShowSpecialDiets && (
        <ButtonFilter
          title={SpecialDietsTitle}
          onClick={handlerSpecialDietsButtonClick}
          countSelected={countActiveSpecialDiets}
          ariaDescription={messages.selectDiets}
          idSrOnly="SpecialDietsFilters"
        />
      )}
      {children}
      <TagsActiveFilters
        filters={{ allergens: activeAllegens, specialDiets: activeSpecialDiets }}
        handlerClick={handlerRemoveTags}
      />
    </>
  );
};

export default Filters;
