import React, { useEffect } from 'react';

import { CommonMenu } from '@/widgets/menu/CommonMenu';
import { PanelTabFilters } from '@/widgets/menu/PanelTabFilters';

import { firstLoadOfMenu } from '@/features/menu/InitialMenu';

import { GetMenuCalendar, getMenu } from '@/entities/menu';
import { AddOrRemoveFromFavorite } from '@/entities/menu/product';

import { APP_ELEMENTS } from '@/shared/constants';
import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';
import { getLoading } from '@/shared/services/loading';
import { LoadingBackground } from '@/shared/services/modal';

const MenuPage: React.FC = () => {
  const loading = useAppSelector(getLoading);
  const dispatch = useAppDispatch<MenuAppDispatch>();

  useEffect(() => {
    dispatch(firstLoadOfMenu(window.menus));
    if (window.menus.model.UserFavoritiesEnabled) {
      dispatch(
        AddOrRemoveFromFavorite({
          isRemoving: false,
        }),
      );
    }
    dispatch(GetMenuCalendar(window.menus.model.LocationId, window.menus.model.StoreIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menus = useAppSelector(getMenu);

  if (!menus) return null;
  return (
    <>
      <PanelTabFilters />
      <CommonMenu />
      <LoadingBackground loading={loading} appElement={APP_ELEMENTS.MENUS} />
    </>
  );
};

export default MenuPage;
