export const DISPLAY_DEEMPHASIZED_TYPES = {
  ///  Expand de-emphasised items on the website for all stations (Current State)
  ExpandAll: 1,
  ///  Collapse de-emphasised items on the website for all stations
  CollapseAll: 2,
  /// Collapse de-emphasised items on the website for specific stations (stores)
  CollapseSelected: 3,
  /// Hide de-emphasised items on the website
  Hide: 4,
};

export const WRAPPER_TABS = 'wrapper-tabs__id';

export enum PRE_SELECTED {
  DAILY_MEAL_MENU_DATE = 'preSelectedMenuDate',
  DAILY_MEAL_MEAL = 'preSelectedMeal',
  DAILY_MEAL_VIEW = 'preSelectedView',
  DAILY_MEAL_START_DATE = 'preSelectedStartDate',
  DAILY_MEAL_END_DATE = 'preSelectedEndDate',
}

export const FILTERS_TYPES = {
  ALLERGENS: 'Allergens',
  SPECIAL_DIETS: 'SpecialDiets',
  ALLERGENS_SPECIAL_DIETS: 'AllergensSpecialDiets',
};
