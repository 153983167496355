import {
  addOrRemoveFavoriteProduct,
  fetchFoodProductDetails,
  getDataForMealCalculator,
} from '@/shared/api/menu';
import { setLoading } from '@/shared/services/loading';
import { NOTIFICATIONS, NotificationService } from '@/shared/services/notification';

import { setFavoriteProducts, setTotalMeal } from './slice';
import type { TActionAddOrRemoveFavoriteProduct, TActionFoodProductDetails } from './types';

export const GetMenuProductsByIds = (): MenuAppThunk => async (dispatch, getState) => {
  // TODO: подумать об анимации загрузки
  try {
    const response = await getDataForMealCalculator(getState().products.TotalMealIds);
    dispatch(setTotalMeal(response));
  } catch (error) {
    NotificationService.open({
      status: NOTIFICATIONS.ERROR,
      text: (error as Error).message,
    });
  }
};

export const FoodProductDetails: TActionFoodProductDetails =
  ({ prod, root }, callback) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    const { locationId, mode, date, periodId } = getState().menu.requestParameters;

    try {
      const response = await fetchFoodProductDetails({
        locationId,
        mode,
        date,
        periodId,
        prod,
        root,
      });
      if (callback) callback(response);
    } catch (error) {
      NotificationService.open({
        status: NOTIFICATIONS.ERROR,
        text: (error as Error).message,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

export const AddOrRemoveFromFavorite: TActionAddOrRemoveFavoriteProduct =
  ({
    catalogId,
    productId,
    MenuProductId,
    storeIds,
    isRemoving,
    selectedPeriodId,
    customDate,
    callback,
  }) =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    if (!getState().menu.Menu?.model.UserFavoritiesEnabled) return;
    const { locationId, mode, date, periodId } = getState().menu.requestParameters;

    try {
      const response = await addOrRemoveFavoriteProduct({
        locationId,
        mode,
        periodId: selectedPeriodId || periodId,
        date: customDate || date,
        catalogId,
        productId,
        MenuProductId,
        storeIds,
        isRemoving,
      });
      dispatch(setFavoriteProducts(response));

      if (callback) callback(response);
    } catch (error) {
      NotificationService.open({
        status: NOTIFICATIONS.ERROR,
        text: (error as Error).message,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
