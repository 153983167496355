import { compareAsc, format } from 'date-fns/fp';
import uniqBy from 'lodash/uniqBy';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useReactToPrint } from 'react-to-print';

import { getFilterProducts } from '@/entities/menu/product';

import { FORMAT_DATE_TIME } from '@/shared/constants';
import { useAppSelector } from '@/shared/hooks/appRedux';
import { useFocus } from '@/shared/hooks/focus';

import { NoProduct } from '../CommonMenu/parts';
import { WeeklyMenuItemBody } from './blocks/WeeklyMenuItemBody';
import WeeklyPrintBtn from './blocks/WeeklyPrintBtn';
import WeeklyPrintContent from './blocks/WeeklyPrintContent';
import { WeeklyMenuItem, WeeklyMenuItemHeader, WeeklyMenuItems } from './parts';

export const WeeklyMenu: React.FC = () => {
  const componentRef = useRef<HTMLDivElement>(null);

  const filteredProducts = useAppSelector(getFilterProducts);

  const { formatMessage } = useIntl();

  const messages = {
    noMenu: formatMessage({ id: 'NoMenu' }),
  };

  const [inputRef, setInputFocus] = useFocus<HTMLButtonElement>();

  const timestamps = filteredProducts.reduce<number[]>((acc, product) => {
    const date = new Date(product.AssignedDate);
    if (date) acc.push(date.getTime());
    return acc;
  }, []);

  const days = uniqBy(timestamps, (timestamp) => timestamp)
    .sort((timestamp1, timestamp2) => compareAsc(timestamp2, timestamp1))
    .map((date) => ({ date, name: format(FORMAT_DATE_TIME.EEEE, date) }));

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: setInputFocus,
    removeAfterPrint: true,
    pageStyle: "padding: '1em'",
  });

  return (
    <div className="MenuWeekly" data-testid="weekly-menu">
      <WeeklyPrintBtn handlePrint={handlePrint} ref={inputRef} />
      <WeeklyPrintContent days={days} products={filteredProducts} ref={componentRef} />
      {filteredProducts.length > 0 && (
        <WeeklyMenuItems>
          {days.map((day) => (
            <WeeklyMenuItem key={day.name}>
              <WeeklyMenuItemHeader>{day.name}</WeeklyMenuItemHeader>
              <WeeklyMenuItemBody products={filteredProducts} day={day} toPrint={false} />
            </WeeklyMenuItem>
          ))}
        </WeeklyMenuItems>
      )}
      <NoProduct
        isShow={!filteredProducts.length}
        dangerouslySetInnerHTML={{
          __html: messages.noMenu,
        }}
      />
    </div>
  );
};
