import { fetchMenu } from '@/shared/api/menu';
import type { IApiResponseGetMenuCalendar } from '@/shared/api/menu/types';
import { Fulfillment, FulfillmentType } from '@/shared/constants';
import type { TMenuInitialData } from '@/shared/types/menu';

import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import type { IMenuSlice } from './types';

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    Menu: null,
    Fulfillment,
    MenuCalendar: null,
    Categories: null,
    requestParameters: { mode: '' },
  } as IMenuSlice,
  reducers: {
    setMenu: (state, action: PayloadAction<TMenuInitialData>) => {
      state.Menu = action.payload;
      state.Categories = action.payload.model.Menu.Categories;
      state.requestParameters = action.payload.requestParameters;
    },
    setFulfillment: (state, action: PayloadAction<FulfillmentType>) => {
      state.Fulfillment = action.payload;
    },
    setMenuCalendar: (state, action: PayloadAction<IApiResponseGetMenuCalendar>) => {
      state.MenuCalendar = action.payload;
    },
    setMealPeriods: (state, action) => {
      if (state.Menu) state.Menu.model.Menu.MenuPeriods = action.payload;
    },
    setSelectedPeriodId: (state, action) => {
      if (state.Menu) state.Menu.model.SelectedPeriodId = action.payload;
    },
    setCurrentCartItemsQuantity: (state, action) => {
      if (state.Menu) state.Menu.model.CurrentCartItemsQuantity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenu.fulfilled, (state, { payload: { menu, mode } }) => {
      if (state.Menu) state.Menu.model = menu;
      state.Categories = menu.Menu.Categories;
      state.requestParameters.mode = mode;
    });
  },
});

export const {
  setMenuCalendar,
  setMealPeriods,
  setSelectedPeriodId,
  setMenu,
  setFulfillment,
  setCurrentCartItemsQuantity,
} = menuSlice.actions;

export const getLinkToLoginPageWithReturnUrl = createSelector(
  ({ menu }: MenuRootState) => menu.Menu?.model.LinkToLoginPageWithReturnUrl,
  (LinkToLoginPageWithReturnUrl) => LinkToLoginPageWithReturnUrl || '',
);
export const getMenu = ({ menu }: MenuRootState): Nullable<TMenuInitialData> => menu?.Menu;
export const isHideProductsShortDescription = createSelector(
  [
    ({ menu }: MenuRootState) => {
      return { HideProductsShortDescription: menu.Menu?.model?.HideProductsShortDescription };
    },
  ],
  ({ HideProductsShortDescription }) => HideProductsShortDescription,
);
export const getIsWeekly = createSelector(
  [
    ({ menu }: MenuRootState) => {
      return { IsWeekly: !!menu.Menu?.model?.IsWeekly };
    },
  ],
  ({ IsWeekly }) => IsWeekly,
);
export const getCategories = createSelector(
  ({ menu }: MenuRootState) => menu?.Categories,
  (Categories) => Categories,
);
export const getIsShowCategories = createSelector(
  ({ menu }: MenuRootState) => !!menu?.Menu?.model.Menu.ShowCategories,
  (ShowCategories) => ShowCategories,
);
export const getMenuStations = createSelector(
  ({ menu }: MenuRootState) => menu?.Menu?.model.Menu.MenuStations,
  (MenuStations) => MenuStations || [],
);
export const getSelectedPeriodId = createSelector(
  ({ menu }: MenuRootState) => menu?.Menu?.model.SelectedPeriodId,
  (SelectedPeriodId) => SelectedPeriodId,
);

export const getMenuCalendar = createSelector(
  ({ menu }: MenuRootState) => menu?.MenuCalendar,
  (MenuCalendar) => MenuCalendar,
);

export const getIsAvailableDatesFoodOrder = createSelector(
  getMenuCalendar,
  (MenuCalendar) =>
    !!MenuCalendar?.AvailableDatesFoodOrder && MenuCalendar?.AvailableDatesFoodOrder.length > 0,
);

export const getFulfillment = ({ menu }: MenuRootState): FulfillmentType => menu.Fulfillment;

export default menuSlice.reducer;
