import React, { memo, useState } from 'react';
import { useIntl } from 'react-intl';

import { DISPLAY_DEEMPHASIZED_TYPES } from '@/entities/menu';

import { PlusOrMinus } from '@/shared/components/Button';
import type { TMenuStation, TProducts } from '@/shared/types/menu';
import { isPropsEqual } from '@/shared/utils/props-comparison';

import { MenuItemsPart } from './blocks/MenuItemsPart';
import { CollapseDeemphasizedButton, Disclaimer, MenuAddOns, MenuCategory, MenuUl } from './parts';

interface IProps {
  productsWithoutDeemphasized: TProducts[];
  productsWithDeemphasized?: TProducts[];
  station?: TMenuStation;
  IsDaily: boolean;
}
const MenuItemsBlock: React.FC<IProps> = ({
  productsWithoutDeemphasized = [],
  productsWithDeemphasized = [],
  station,
  IsDaily,
}) => {
  const initialCollapse =
    station == null ||
    (station.DisplayDeemphasizedType === DISPLAY_DEEMPHASIZED_TYPES.CollapseSelected &&
      station.CollapseDeemphasized) ||
    station.DisplayDeemphasizedType === DISPLAY_DEEMPHASIZED_TYPES.CollapseAll ||
    station.DisplayDeemphasizedType === DISPLAY_DEEMPHASIZED_TYPES.Hide;

  const [isCollapseDeemphasized, setCollapseDeemphasized] = useState(initialCollapse);

  const { formatMessage } = useIntl();

  const messages = {
    seeMore: formatMessage({ id: 'SeeMore' }),
    seeLess: formatMessage({ id: 'SeeLess' }),
    alsoAvailable: formatMessage({ id: 'AlsoAvailable' }),
  };

  const productBlocks = productsWithoutDeemphasized.length !== 0 && (
    <MenuCategory className="MenuCategory">
      {productsWithoutDeemphasized.map((products, i) => {
        // eslint-disable-next-line react/no-array-index-key
        return <MenuItemsPart key={`${products.length}${i}`} products={products} />;
      })}
    </MenuCategory>
  );
  const collapseDeemphasizedTitle = isCollapseDeemphasized ? messages.seeMore : messages.seeLess;

  const showDeemphasizedButton =
    IsDaily && station?.DisplayDeemphasizedType !== DISPLAY_DEEMPHASIZED_TYPES.ExpandAll;

  const deemphasizedProductBlocks = productsWithDeemphasized.length !== 0 && (
    <>
      {showDeemphasizedButton && (
        <CollapseDeemphasizedButton
          click={() => setCollapseDeemphasized(!isCollapseDeemphasized)}
          aria-label={collapseDeemphasizedTitle}
          aria-expanded={!station?.CollapseDeemphasized}
          aria-controls={`displayDeemphasized_${station?.StationId}`}
          title={collapseDeemphasizedTitle}
          className="CollapseDeemphasizedButton"
          height="24px"
        >
          <PlusOrMinus isPlus={isCollapseDeemphasized} />
          <span
            dangerouslySetInnerHTML={{
              __html: collapseDeemphasizedTitle,
            }}
          />
        </CollapseDeemphasizedButton>
      )}
      <MenuAddOns
        className="MenuAddOns"
        role="region"
        id={`displayDeemphasized_${station?.StationId}`}
        hide={IsDaily && isCollapseDeemphasized}
      >
        {productsWithoutDeemphasized && productsWithoutDeemphasized.length > 0 && (
          <Disclaimer
            className="Disclaimer"
            dangerouslySetInnerHTML={{
              __html: messages.alsoAvailable,
            }}
          />
        )}

        {productsWithDeemphasized.map((products, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItemsPart key={`${products.length}${i}`} margin="0 0 30px 0" products={products} />
          );
        })}
      </MenuAddOns>
    </>
  );

  return (
    <MenuUl className="MenuUl" role="list">
      {productBlocks}
      {deemphasizedProductBlocks}
    </MenuUl>
  );
};

export default memo(MenuItemsBlock, isPropsEqual);
