import React from 'react';
import { useIntl } from 'react-intl';

import { useFocus } from '@/shared/hooks/focus';

import AllergensAndDietsSvg from '../../../icons/allergensAndDiets.svg';
import PencilSvg from '../../../icons/pencil.svg';
import {
  CountActiveFilters,
  FilterButton,
  FilterButtonText,
  FilterButtonWrapper,
  FilterButtonWrapperSVG,
} from '../parts';

interface IProps {
  title: string;
  onClick: (callback: VoidFunction) => void;
  countSelected: number;
  ariaDescription: string;
  isMobile?: boolean;
  idSrOnly: string;
}

const ButtonFilter: React.FC<IProps> = ({
  title,
  ariaDescription,
  onClick,
  countSelected,
  isMobile = false,
  idSrOnly,
}) => {
  const { formatMessage } = useIntl();
  const messages = {
    selections: formatMessage({ id: 'Selections' }),
    buttonAriaLabel: formatMessage(
      { id: 'Menus.Filters.Buttons.AriaLabel' },
      { 0: ariaDescription, 1: countSelected },
    ),
  };

  const [ref, setFocus] = useFocus<HTMLButtonElement>();

  const handlerClick = (): void => {
    onClick(setFocus);
  };
  return (
    <FilterButtonWrapper className="FilterWrapper">
      <FilterButton onClick={handlerClick} aria-describedby={idSrOnly} ref={ref}>
        {isMobile && (
          <AllergensAndDietsSvg
            data-testid="svg-icon"
            className="svg-icon svg-filters AllergensAndDietsSvg"
            role="presentation"
            aria-hidden="true"
            focusable="false"
          />
        )}
        <FilterButtonText className="FilterButtonText">{title}</FilterButtonText>
        {countSelected > 0 && (
          <CountActiveFilters aria-hidden className="CountActiveFilters">
            {isMobile ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: `${countSelected} ${messages.selections}`,
                }}
              />
            ) : (
              countSelected
            )}
          </CountActiveFilters>
        )}
        <FilterButtonWrapperSVG className="FilterButtonWrapperSVG">
          <PencilSvg
            className="svg-icon svg-filters PencilSvg"
            role="presentation"
            aria-hidden="true"
            focusable="false"
          />
        </FilterButtonWrapperSVG>
      </FilterButton>
      <span id={idSrOnly} className="sr-only">
        {countSelected > 0 ? messages.buttonAriaLabel : ariaDescription}
      </span>
    </FilterButtonWrapper>
  );
};

export default ButtonFilter;
