import React from 'react';

import MealCalculatorFilter from '../../../icons/mealCalculatorFilter.svg';

const IconMealCalculator: React.FC = () => {
  return (
    <MealCalculatorFilter
      className="svg-icon svg-filters MealCalculatorFilter"
      role="presentation"
      aria-hidden="true"
      focusable="false"
      style={{
        fill: '#000',
      }}
    />
  );
};

export default IconMealCalculator;
