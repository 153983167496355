import React from 'react';
import { useIntl } from 'react-intl';

import { getFulfillment, getMenu } from '@/entities/menu';
import { FoodProductDetails } from '@/entities/menu/product';

import { ModalProduct, Nutrition } from '@/shared/components/ProductDetails';
import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';
import { ModalService } from '@/shared/services/modal';
import type { TProduct } from '@/shared/types/menu';
import { hasValue } from '@/shared/utils/validation';

import {
  HeaderItemNameLinkWeeklyMenuButton,
  HeaderItemNameLinkWeeklyMenuText,
  HeaderItemTopWeeklyWrapper,
  WeeklyMenuItemPrice,
} from '../parts';

interface IProps {
  product: TProduct;
  toPrint: boolean;
}
export const MenuItem: React.FC<IProps> = ({ product, toPrint }) => {
  const model = useAppSelector(getMenu)?.model;
  const dispatch = useAppDispatch<MenuAppDispatch>();

  const { formatMessage } = useIntl();
  const messages = {
    calories: formatMessage({ id: 'Calories' }),
    viewProductDetailsTitle: formatMessage({ id: 'ViewProductDetailsTitle' }),
    addCart: formatMessage({ id: 'AddCart' }),
  };

  const fulfillment = useAppSelector(getFulfillment);

  const isProductCalories = !model?.HideProductsCalorie && hasValue(product?.Product?.Calories);
  const isOpenNutritionalInformation =
    !model?.HideProductsNutritional &&
    !product?.Product?.IsBulkProduct &&
    product?.Product?.HasNutritionalInformation &&
    isProductCalories;

  const HandleMenuItemClick = (): void => {
    dispatch(
      FoodProductDetails(
        {
          prod: product?.ProductId,
          root: product?.CatalogId,
        },
        (productDetailsWithModifiers) =>
          ModalService.open(ModalProduct, {
            product: product.Product,
            productDetailsWithModifiers,
            nameButton: messages.addCart,
            fulfillment,
            nutritionComponent: <Nutrition productInfo={product.Product} />,
            isOpenNutritionalInformation,
          }),
      ),
    );
  };

  const isShowPrice = !!(model?.DisplayPriceOnTheMenu && product.Product.CurrentPrice);

  const isShowCalories = !!(
    !model?.HideProductsCalorie &&
    product.Product.Calories &&
    hasValue(product.Product.Calories)
  );

  const productName = hasValue(product.Product.MarketingName)
    ? product.Product.MarketingName
    : product.Product.DisplayName;
  return (
    <div style={{ marginBottom: '10px' }} key={productName}>
      <HeaderItemTopWeeklyWrapper toPrint={toPrint}>
        {isOpenNutritionalInformation ? (
          <HeaderItemNameLinkWeeklyMenuButton
            click={HandleMenuItemClick}
            className="HeaderItemNameLinkWeeklyMenu"
            title={messages.viewProductDetailsTitle}
            toPrint={toPrint}
          >
            {productName}
          </HeaderItemNameLinkWeeklyMenuButton>
        ) : (
          <HeaderItemNameLinkWeeklyMenuText toPrint={toPrint}>
            {productName}
          </HeaderItemNameLinkWeeklyMenuText>
        )}

        {isShowPrice && (
          <WeeklyMenuItemPrice className="ItemPrice" toPrint={toPrint}>
            ${product.Product.CurrentPrice}
          </WeeklyMenuItemPrice>
        )}
      </HeaderItemTopWeeklyWrapper>

      {isShowCalories && (
        <span
          style={{ fontSize: toPrint ? '7px' : 'unset', display: 'block' }}
          className="ItemCalories"
          dangerouslySetInnerHTML={{
            __html: `${product.Product.Calories} ${messages.calories}`,
          }}
        />
      )}
    </div>
  );
};
