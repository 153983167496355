import React from 'react';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@/shared/hooks/appRedux';

import { getTotalMealIds } from '../../../model';
import { CalcMsgStyled } from '../parts';
import { ButtonMealCalculator } from './ButtonMealCalculator';

interface IProps {
  menuProductId: string;
  marketingName: Nullable<string>;
}

export const MealCalcNutrition: React.FC<IProps> = ({ menuProductId, marketingName }) => {
  const { formatMessage } = useIntl();

  const messages = {
    addCalc: formatMessage({ id: 'AddTo' }, { 0: '' }).replace('  ', ' '),
    removeCalc: formatMessage({ id: 'RemoveFrom' }, { 0: '' }).replace('  ', ' '),
  };

  const isProductInTotalMeal = useAppSelector(getTotalMealIds).includes(menuProductId);
  return (
    <>
      <ButtonMealCalculator menuProductId={menuProductId} marketingName={marketingName} />
      <CalcMsgStyled
        dangerouslySetInnerHTML={{
          __html: isProductInTotalMeal ? messages.removeCalc : messages.addCalc,
        }}
      />
    </>
  );
};
