import styled, { css } from 'styled-components';

import type { IDefaultTheme } from '@/shared/themes/types';

export const FilterItemsInModal = styled.div``;

export const FilterButtonWrapper = styled.div`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    display: block;
    margin-top: 0px;

    ${theme.media.tablet} {
      display: inline-block;
      margin-top: 10px;
    }
  `}
`;

export const FilterButton = styled.button`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    width: 100%;
    border: none;
    height: 40px;
    padding: 2px 2px 2px 21px;
    margin: 1px 1px 16px 1px;
    width: 100%;
    border-radius: 22.5px;
    background-color: #efefef;
    display: inline-flex;
    position: relative;
    box-sizing: border-box;

    ${theme.media.tablet} {
      margin: 1px 16px 1px 1px;
      padding: 1px 2px 2px 16px;
      width: auto;
    }
  `}
`;

export const FilterButtonText = styled.span`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    display: none;

    ${theme.media.tablet} {
      display: block;
      margin: 6px 8px 6px 0;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: #000000;
      width: max-content;
    }
  `}
`;

export const FilterButtonWrapperSVG = styled.div`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    width: 56px;
    height: 38px;
    padding: 2px 16px;
    border-radius: 0px 22.5px 22.5px 0px;
    background-color: #ffffff;
    border: none;
    margin: 0;
    outline: none;
    position: absolute;
    top: 1px;
    right: 1px;

    ${theme.media.tablet} {
      position: relative;
      top: 0;
      right: 0;
    }
  `}
`;

export const CountActiveFilters = styled.span`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    width: auto;
    position: absolute;
    right: 56px;

    height: 24px;
    margin: 6px 8px 6px 0px;

    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #000;

    ${theme.media.tablet} {
      width: 8px;
      position: relative;
      right: 0;
    }
  `}
`;

export const ButtonActiveTag = styled.button`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    height: 24px;
    padding: 0 8px;
    border-radius: 4px;
    border: 0px;
    background-color: #efefef;
    margin: 8px 16px 16px 0px;

    ${theme.media.tablet} {
      margin: 24px 16px 16px 0px;
    }
  `}
`;

export const CloseTag = styled.div`
  color: ${({ theme }: { theme: IDefaultTheme }) => theme.defaultPrimaryColor};
  display: inline-block;
`;
