import { sortBy, sortedUniqBy } from 'lodash/fp';

import { createSelector } from '@reduxjs/toolkit';

import { filterProducts } from '../utils';
import { TTotalmeal } from './types';

export const getIsProducts = createSelector(
  ({ products }: MenuRootState) => products.list,
  (products) => !!products.length,
);
export const getTotalMealIds = ({ products }: MenuRootState): string[] => products?.TotalMealIds;
export const getTotalMeal = ({ products }: MenuRootState): Nullable<TTotalmeal> =>
  products.TotalMeal;

const cacheMenuStations = createSelector(
  ({ menu }: MenuRootState) => menu.Menu?.model,
  (model) => ({
    MenuStations: model?.Menu.MenuStations,
    SelectedPeriodId: model?.SelectedPeriodId,
  }),
);

const cacheProducts = createSelector(
  ({ products }: MenuRootState) => products,
  (products) => products.list,
);

export const getActiveStantions = createSelector(
  cacheMenuStations,
  cacheProducts,
  ({ MenuStations, SelectedPeriodId }, products) => {
    const stations = sortBy(
      (station) => station.StationRank,
      MenuStations?.filter((station) => Number(station.PeriodId) === Number(SelectedPeriodId)),
    );

    return stations.filter((station) => {
      const filterList = products.filter((product) => product.StationId === station.StationId);
      return filterList.length !== 0;
    });
  },
);

export const getFavoriteProductIds = ({ products }: MenuRootState): string[] =>
  products?.FavoriteProductIds;

const cacheFavoriteProductIds = createSelector(
  ({ products }: MenuRootState) => products,
  (products) => products.FavoriteProductIds,
);

export const getFilterProducts = createSelector(
  [
    ({ filters, products }: MenuRootState) => {
      return { filters: filters.filters, products: products.list };
    },
  ],
  ({ filters, products }) => {
    return filterProducts(filters, products);
  },
);

export const getFavoriteProducts = createSelector(
  cacheFavoriteProductIds,
  getFilterProducts,
  (FavoriteProductIds, products) => {
    const reorderList = products.filter((product) => product?.IsTopPurchased);
    const sortList = sortedUniqBy(
      'MenuProductId',
      products.filter(
        ({ MenuProductId }) =>
          FavoriteProductIds.includes(MenuProductId) &&
          !reorderList.some((p) => p.MenuProductId === MenuProductId),
      ),
    );
    return [...reorderList, ...sortList];
  },
);

export const getIsPaypalOnList = createSelector(
  [
    ({ products }: MenuRootState) => {
      return products.list;
    },
  ],
  (products) => {
    return products.some((product) => product.Product?.PayPalEnabled);
  },
);
