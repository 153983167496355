import { MenuReducer } from '@/entities/menu';
import { FilterReducer } from '@/entities/menu/filter';
import { ProductReducer } from '@/entities/menu/product';

import { LoadingReducer } from '@/shared/services/loading';

import { Action, ThunkAction, combineReducers, configureStore } from '@reduxjs/toolkit';

const rootReducer = combineReducers({
  menu: MenuReducer,
  products: ProductReducer,
  filters: FilterReducer,
  loading: LoadingReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production' && {
    name: 'Menus Redux FSD',
  },
});

export default store;

export type MenuRootState = ReturnType<typeof store.getState>;
export type MenuAppDispatch = typeof store.dispatch;
export type MenuAppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  MenuRootState,
  unknown,
  Action<string>
>;
