import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';

import {
  getChoosenMeal,
  getWeeklyMenuEndDate,
  getWeeklyMenuStartDate,
} from '@/entities/menu/filter';

import { useAppSelector } from '@/shared/hooks/appRedux';
import { TProducts } from '@/shared/types/menu';
import { parseWeek } from '@/shared/utils/parseDate';

import { WeeklyMenuItemBody } from './WeeklyMenuItemBody';

interface IProps {
  days: {
    date: number;
    name: string;
  }[];

  products: TProducts;
}

const WeeklyPrintContent = forwardRef<HTMLDivElement, IProps>(function PrintBtn(
  { days, products },
  ref,
) {
  const startDate = useAppSelector(getWeeklyMenuStartDate);
  const endDate = useAppSelector(getWeeklyMenuEndDate);
  const choosenMeal = useAppSelector(getChoosenMeal);
  const { formatMessage } = useIntl();

  const messages = {
    title: formatMessage({ id: 'Menus.Products.WeeklyMenu.Title' }, { 0: choosenMeal?.name }),
  };
  return (
    <div style={{ display: 'none' }}>
      <div className="r-flex r-flex-row r-flex-wrap r-justify-start" ref={ref}>
        <div className="r-w-full r-p-4">
          <div className="r-w-fit r-text-base r-mx-auto r-mt-4">{messages.title}</div>
          <div className="r-w-fit r-text-sm r-mx-auto r-mt-4">{parseWeek(startDate, endDate)}</div>
          <div className="r-flex r-justify-start r-gap-2">
            {days.map((day) => (
              <div key={day.name} className="r-flex-1">
                <h2 className="r-text-lg r-text-center">{day.name}</h2>
                <div className="r-p-1">
                  <WeeklyMenuItemBody products={products} day={day} toPrint />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default WeeklyPrintContent;
