import styled, { css } from 'styled-components';

import { ButtonText } from '@/shared/components/Button';
import type { IMenuTheme } from '@/shared/themes/types';

export const MenuItemsDiv = styled.div<{ margin?: string }>`
  ${({ theme, margin }: { theme: IMenuTheme; margin?: string }) => css`
    padding: 0;
    margin-left: 16px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: inherit;
    justify-content: center;
    margin: ${margin || '0'};
    padding: 0px;
    gap: ${theme.gapBetweenCards}px;

    &:last-child {
      margin: ${margin && '0'};
    }

    ${theme.media.tablet} {
      justify-content: start;
      gap: 18px;
    }

    ${theme.media.desktop} {
      justify-content: start;
      gap: ${theme.gapBetweenCardsForLg}px;
    }

    ${theme.media.largeDesktop} {
      gap: ${theme.gapBetweenCards}px;
    }
  `}
`;

export const MenuUl = styled.ul`
  height: inherit;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const MenuCategory = styled.div`
  ${({ theme, hide }: { theme: IMenuTheme; hide?: boolean }) => css`
    z-index: 0;
    display: ${hide ? 'none' : 'flex'};
    flex-direction: column;
    gap: ${theme.gapBetweenCards}px;
  `}
`;
export const NoProduct = styled.p`
  display: ${({ isShow }: { isShow: boolean }) => (isShow ? 'block' : 'none')};
  text-align: center;
  width: 100%;
`;

export const Disclaimer = styled.span`
  margin: 0 0 16px 0;
  display: inline-block;
`;

export const MenuAddOns = styled.div`
  display: ${({ hide }: { hide?: boolean }) => (hide ? 'none' : 'block')};
  padding-top: 16px;
`;

export const CollapseDeemphasizedButton = styled(ButtonText)`
  margin: 24px 0 0 0;
  font-size: ${({ toPrint }: { toPrint?: boolean }) => (toPrint ? '10px' : '18px')};
  font-weight: normal;
`;

export const CategoryName = styled.h2`
  line-height: 22px;
  margin: 8px 0 0 0 !important;
  font-weight: 600;
  font-size: ${({ toPrint }: { toPrint?: boolean }) => (toPrint ? '12px' : '18px')};
  color: #555;
  padding: 0.33em 0.75em;
  display: block;
`;

export const MenuParentCategory = styled.div`
  display: block;
`;

export const MenuStation = styled.div`
  width: 100%;
  display: block;
`;

export const MenuWrapperDaily = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 32px;
`;

export const MenuDaily = styled.div`
  width: 100%;
  outline: none;
  order: 1;
`;

export const MenuContainer = styled.div`
  padding: 0;
  margin-top: 33px;
  @media (max-width: 767px) {
    margin-top: 80px;
  }
`;

export const StationDescription = styled.div`
  ${({ theme }: { theme: IMenuTheme }) => css`
    flex: 1;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;

    ${theme.media.tablet} {
    }

    ${theme.media.desktop} {
      flex: 0 0
        ${theme.percentForNCards(2, 3, theme.cardWidthFromMdToLg, theme.gapBetweenCardsForLg)}%;
    }

    ${theme.media.largeDesktop} {
      flex: 0 0 ${theme.percentForNCards(2)}%;
    }
  `}
`;

export const StationHeaderTitle = styled.h2`
  padding: 0 0.5em;
  padding-left: 0px;
  margin: 0 !important;
  font-weight: 600;
  font-size: ${({ toPrint }: { toPrint?: boolean }) => (toPrint ? '12px' : '24px')};
  line-height: ${({ toPrint }: { toPrint?: boolean }) => (toPrint ? '16px' : '33px')};
`;

export const StationHeader = styled.div`
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
`;

export const StationHeaderBlock = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const StationImg = styled.div`
  ${({ theme }: { theme: IMenuTheme }) => css`
    flex: 0 0 30%;
    max-height: 100px;

    ${theme.media.desktop} {
      flex: 0 0
        ${theme.percentForNCards(
          1,
          undefined,
          theme.cardWidthFromMdToLg,
          theme.gapBetweenCardsForLg,
        )}%;
    }

    ${theme.media.largeDesktop} {
      flex: 0 0 ${theme.percentForNCards()}%;
    }
  `}
`;
