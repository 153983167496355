import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { getMenu } from '@/entities/menu';

import { useAppSelector } from '@/shared/hooks/appRedux';
import { srSpeak } from '@/shared/utils/srSpeak';

import CartIconSvg from '../../icons/cart.svg';
import { CartButtonDiv, CartItemsText, CheckOutButton } from './parts';

const MINICART_COOKIE_NAME = 'MiniCartCookie';

const CartButton: React.FC = () => {
  const menus = useAppSelector(getMenu);
  const { formatMessage } = useIntl();

  const linkToCart = `${window.location.origin}${menus?.model?.CartUrl}`;

  const allCookies = document.cookie.split(';');
  const cookie = allCookies.find((c) => {
    const [name] = c.trim().split('=');
    return name === MINICART_COOKIE_NAME;
  });
  const itemsCount = cookie ? parseInt(cookie.split('=')[1].split(',')[0], 10) : 0;
  const messages = {
    checkout: formatMessage({ id: 'Menus.Menu.Button.CheckOut' }),
    ariaLive: formatMessage({ id: 'Menus.Filters.Cartbutton.AriaLive' }, { 0: itemsCount }),
  };

  useEffect(() => {
    if (itemsCount) {
      srSpeak(messages.ariaLive);
    }
  }, [itemsCount, messages.ariaLive]);
  return (
    <CartButtonDiv isShow={!!itemsCount}>
      <CartIconSvg
        data-testid="svg-icon"
        className="svg-icon svg-cart-button CartIconSvg"
        role="presentation"
        aria-hidden="true"
        focusable="false"
      />
      <CartItemsText aria-hidden="true">{itemsCount}</CartItemsText>
      <CheckOutButton
        className="react-checkout-button"
        width="90px"
        height="38.5px"
        link={linkToCart}
        margin="-1px -1px 0 0"
        padding="1px 0 0 0"
        fontSize="14px"
        fontWeight="bold"
        aria-label={`${messages.checkout}`}
      >
        <span dangerouslySetInnerHTML={{ __html: messages.checkout }} />
      </CheckOutButton>
    </CartButtonDiv>
  );
};

export default CartButton;
