import React from 'react';
import { useIntl } from 'react-intl';

import { getTotalMeal, getTotalMealIds } from '@/entities/menu/product';

import { useAppSelector } from '@/shared/hooks/appRedux';
import { useFocus } from '@/shared/hooks/focus';
import { ModalService } from '@/shared/services/modal';

import PencilSvg from '../../icons/pencil.svg';
import { MainWrapper, TextSpan } from '../parts';
import IconMealCalculator from './blocks/IconMealCalculator';
import MealCaculatorModal from './modal/MealCaculatorModal';
import { MealCalculatorButton, WrapperSVG } from './parts';

const MealCalculatorBlock: React.FC = () => {
  const totalMeal = useAppSelector(getTotalMealIds).length;
  const productInTotalMeal = useAppSelector(getTotalMeal)?.Product;
  const calories = productInTotalMeal?.NutritionalTree;

  const [inputRef, setInputFocus] = useFocus<HTMLButtonElement>();

  const totalCalories = calories?.[0].Value;

  const { formatMessage } = useIntl();
  const messages = {
    mealCalculator: formatMessage({ id: 'mealCalculator' }),
    totalMeal: formatMessage(
      { id: 'Menus.Filters.MealCalculator.Button.Name.TotalMeal' },
      { count: totalMeal },
    ),
    cal: formatMessage({ id: 'Menus.Filters.MealCalculator.Button.Name.Cal' }),
    buttonSelectedAriaLabel: formatMessage(
      { id: 'Menus.Filters.MealCalculator.Button.Selected.AriaLabel' },
      { 0: totalMeal, 1: totalCalories },
    ),
  };

  const handleOpenModal = (): void => {
    ModalService.open(MealCaculatorModal, {
      setInputFocus,
    });
  };

  return (
    <MainWrapper className="MealCalculator">
      <MealCalculatorButton
        onClick={handleOpenModal}
        aria-label={totalMeal > 0 ? messages.buttonSelectedAriaLabel : messages.mealCalculator}
        ref={inputRef}
        className="MealCalculatorButton"
      >
        <IconMealCalculator />
        {totalMeal > 0 && (
          <TextSpan
            className="ChangeLocationTextSpan"
            dangerouslySetInnerHTML={{
              __html: `${messages.totalMeal}/ ${totalCalories} ${messages.cal}`,
            }}
          />
        )}
        <WrapperSVG className="WrapperSVG">
          <PencilSvg
            className="svg-icon svg-filters PencilSvg"
            role="presentation"
            aria-hidden="true"
            focusable="false"
          />
        </WrapperSVG>
      </MealCalculatorButton>
    </MainWrapper>
  );
};

export default MealCalculatorBlock;
