import sortBy from 'lodash/sortBy';

import type { TFiltersMenu } from '@/entities/menu/filter/type';

import type { TMenuStations, TProducts } from '@/shared/types/menu';

export const filterProducts = (filters: TFiltersMenu, products: TProducts): TProducts => {
  const { allergens, specialDiets } = filters;
  const activeAllergensFilters = allergens.filter((filter) => filter?.IsActive);
  const activeSpecialDietsFilters = specialDiets.filter((filter) => filter?.IsActive);
  if (activeAllergensFilters?.length <= 0 && activeSpecialDietsFilters?.length <= 0)
    return products;

  const filtredProducts = products.filter((product) => {
    const availableFilters = Object.entries(product?.Product?.AvailableFilters);

    // NOTE: true for an empty array.
    const isNotAllergen = !activeAllergensFilters.some((allergen) =>
      // Note: Since it is unknown whether the product has a property (it is null), we exclude it from the list (task 29086)
      availableFilters.some(([id, value]) => (value == null || value) && allergen.Id === id),
    );
    // NOTE: true for an empty array.
    const isSpesiaDiet = activeSpecialDietsFilters.every((diet) =>
      availableFilters.some(([id, value]) => value && diet.Id === id),
    );
    return isNotAllergen && isSpesiaDiet;
  });

  return filtredProducts;
};

export const activeStantions = (state: MenuRootState, isShowFavorite: boolean): TMenuStations => {
  const RecommendedForYou = {
    StationId: '1',
  };
  let stations = sortBy(
    state.menu.Menu?.model.Menu.MenuStations.filter(
      (station) => station.PeriodId === state.menu.Menu?.model?.SelectedPeriodId,
    ),
    (station) => station.StationRank,
  );

  const products = filterProducts(state.filters.filters, state.products.list);

  stations = stations.filter((station) => {
    const list = products.filter((product) => product.StationId === station.StationId);
    return list.length !== 0;
  });

  const isFavoriteProducts = products.some(({ MenuProductId }) =>
    state.products.FavoriteProductIds.includes(MenuProductId),
  );

  if (
    isShowFavorite &&
    state.menu.Menu?.model.UserFavoritiesEnabled &&
    state.products.FavoriteProductIds.length > 0 &&
    isFavoriteProducts
  ) {
    // @ts-expect-error: skip
    stations.unshift(RecommendedForYou);
  }

  return stations;
};
