import { differenceWith, isEqual } from 'lodash/fp';

import { apiSaveFilters } from '@/shared/api/menu';
import { NOTIFICATIONS, NotificationService } from '@/shared/services/notification';

import type { IFiltersSlice } from '../type';
import { setFrontFilters } from './slice';

export const SaveFilters =
  (filters: IFiltersSlice['filters']): MenuAppThunk =>
  async (dispatch, getState) => {
    try {
      const { allergens: oldAllergens, specialDiets: oldSpecialDiets } = getState().filters.filters;
      const oldFilters = [...oldAllergens, ...oldSpecialDiets];
      const { allergens, specialDiets } = filters;
      const newFilters = [...allergens, ...specialDiets];
      const diffBetweenFilters = differenceWith(isEqual, newFilters, oldFilters).map((filter) => ({
        id: filter.Id,
        isActive: filter.IsActive,
      }));

      if (diffBetweenFilters?.length) {
        dispatch(setFrontFilters(filters));

        await apiSaveFilters({ filterItems: diffBetweenFilters });
      }
    } catch (error) {
      NotificationService.open({
        status: NOTIFICATIONS.ERROR,
        text: (error as Error).message,
      });
    }
  };
