import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { getMenu } from '@/entities/menu';
import { getSearchText, setSearchText } from '@/entities/menu/filter';
import { setProducts } from '@/entities/menu/product';

import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';
import { useFocus } from '@/shared/hooks/focus';
import type { TProducts } from '@/shared/types/menu';
import { srSpeak } from '@/shared/utils/srSpeak';

import SearchSvg from '../icons/search.svg';
import { MainWrapper, SearchInput, SearchInputClean } from './parts';

const Search: React.FC = () => {
  const model = useAppSelector(getMenu)?.model;
  const { formatMessage } = useIntl();

  const searchInputText = useAppSelector(getSearchText);
  const allProducts = model?.Menu?.MenuProducts;

  const dispatch = useAppDispatch<MenuAppDispatch>();
  const setSearchInputText = useCallback(
    (value: string): void => {
      dispatch(setSearchText(value));
    },
    [dispatch],
  );
  const setFilteredProducts = (products: TProducts): void => {
    dispatch(setProducts(products));
  };

  const messages = {
    searchAriaLabel: formatMessage({ id: 'SearchAriaLabel' }),
    searchPlaceholder: formatMessage({ id: 'SearchPlaceholder' }),
    cleanSearchText: formatMessage({ id: 'CleanSearchText' }),
    menuIsFiltered: formatMessage({ id: 'MenuIsFiltered' }),
    buttonClearAriaLabel: formatMessage({ id: 'Menus.Search.Button.Clear.AriaLabel' }),
  };

  const FormatString = (text: string): string => {
    return text.trim().toLowerCase();
  };

  const [inputRef, setInputFocus] = useFocus<HTMLInputElement>();

  useEffect(() => {
    setSearchInputText('');
  }, [allProducts, setSearchInputText]);

  const searchProducts = (text: string): void => {
    if (!allProducts?.length) return;

    const filteredProducts = allProducts.filter((product) => {
      const name = product.Product.MarketingName && FormatString(product.Product.MarketingName);
      const displayName = product.Product.DisplayName && FormatString(product.Product.DisplayName);
      const search = FormatString(text);

      return (name && name.includes(search)) || (displayName && displayName.includes(search));
    });

    setFilteredProducts(filteredProducts);
  };

  const handlerChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const searchText = event.target.value;
    setSearchInputText(searchText);
    searchProducts(searchText);
    srSpeak(messages.menuIsFiltered, 'polite');
  };

  const cleanSearchInput = (): void => {
    const searchText = '';
    setSearchInputText(searchText);
    searchProducts(searchText);
    srSpeak(messages.cleanSearchText, 'polite');
    setInputFocus();
  };

  const removeAriaHidden = (): void => {
    inputRef.current?.removeAttribute('aria-hidden');
  };

  const openSearchInput = (): void => {
    if (inputRef.current === document.activeElement) return;
    setInputFocus();
  };

  return (
    <MainWrapper>
      <SearchSvg
        onClick={openSearchInput}
        className="svg-icon-search"
        role="presentation"
        aria-hidden="true"
        focusable="false"
      />
      <SearchInput
        aria-label={messages.searchAriaLabel}
        id="SearchInput"
        type="search"
        onFocus={removeAriaHidden}
        placeholder={messages.searchPlaceholder}
        onChange={handlerChange}
        isText={!!searchInputText}
        value={searchInputText}
        ref={inputRef}
      />
      {searchInputText && (
        <SearchInputClean
          aria-label={messages.buttonClearAriaLabel}
          id="SearchInputClean"
          onClick={cleanSearchInput}
        >
          &#10006;
        </SearchInputClean>
      )}
    </MainWrapper>
  );
};

export default Search;
