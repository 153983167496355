import React from 'react';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from '@/shared/hooks/appRedux';
import { srSpeak } from '@/shared/utils/srSpeak';

import { GetMenuProductsByIds, getTotalMealIds, setTotalMealIds } from '../../../model';
import MealCalculatorDoneSVG from '../icons/mealCalculatorDone.svg';
import MealCalculatorPlusSVG from '../icons/mealCalculatorPlus.svg';
import { ButtonMealCalc } from '../parts';

interface IProps {
  menuProductId: string;
  marketingName: Nullable<string>;
  'data-testid'?: string;
}
export const ButtonMealCalculator: React.FC<IProps> = ({
  menuProductId,
  marketingName,
  'data-testid': dataTestId = 'button-meal-calculator',
}) => {
  const isProductInTotalMeal = useAppSelector(getTotalMealIds).includes(menuProductId);
  const dispatch = useAppDispatch<MenuAppDispatch>();
  const { formatMessage } = useIntl();

  const messages = {
    add: formatMessage({ id: 'AddTo' }, { 0: marketingName }),
    remove: formatMessage({ id: 'RemoveFrom' }, { 0: marketingName }),
    ariaLabel: formatMessage({ id: 'Global.ProductCard.MealCalculatorButton.AraaLabel' }),
  };

  const handlerClick = (): void => {
    srSpeak(!isProductInTotalMeal ? messages.remove : messages.add);

    dispatch(setTotalMealIds({ MenuProductId: menuProductId }));
    dispatch(GetMenuProductsByIds());
  };

  const ariaDescribedby = `AriaDescribedbyMealCalculator_${menuProductId}`;
  return (
    <ButtonMealCalc
      onClick={handlerClick}
      className="ButtonMealCalc"
      product-id={menuProductId}
      aria-label={messages.ariaLabel}
      aria-describedby={ariaDescribedby}
      product-name={marketingName}
      data-testid={dataTestId}
    >
      <span id={ariaDescribedby} className="sr-only">
        {!isProductInTotalMeal ? messages.add : messages.remove}
      </span>
      {!isProductInTotalMeal ? (
        <MealCalculatorPlusSVG
          className="svg-icon MealCalculatorPlus"
          viewBox="0 0 24 24"
          role="presentation"
          aria-hidden="true"
          focusable="false"
          data-testid="meal-calculator-plus"
        />
      ) : (
        <MealCalculatorDoneSVG
          className="svg-icon MealCalculatorDone"
          viewBox="0 0 24 24"
          role="presentation"
          aria-hidden="true"
          focusable="false"
          data-testid="meal-calculator-done"
        />
      )}
    </ButtonMealCalc>
  );
};
