import React from 'react';

import { Img } from '@/shared/components/Img';
import type { TMenuStation } from '@/shared/types/menu';
import { hasValue } from '@/shared/utils/validation';

import {
  StationDescription,
  StationHeader,
  StationHeaderBlock,
  StationHeaderTitle,
  StationImg,
} from '../parts';

interface IProps {
  station:
    | TMenuStation
    | {
        Name: string;
      };

  withOutImg?: boolean;
}
export const StationHeaderComponent: React.FC<IProps> = ({ station, withOutImg = false }) => {
  if (!hasValue(station.Name)) return null;
  return (
    <StationHeader className="StationHeader">
      <StationHeaderTitle
        className="StationHeaderTitle"
        dangerouslySetInnerHTML={{
          __html: station.Name!,
        }}
      />
      <StationHeaderBlock className="StationHeaderBlock">
        <StationImg className="StationImg">
          {(station as TMenuStation)?.StationImagePath && (
            <Img
              src={(station as TMenuStation).StationImagePath!}
              height="100px"
              margin="auto"
              mobMargin="auto"
              hidden={withOutImg}
              alt={station.Name!}
            />
          )}
        </StationImg>
        {hasValue((station as TMenuStation)?.StationDescription) && (
          <StationDescription
            className="StationDescription"
            dangerouslySetInnerHTML={{
              __html: (station as TMenuStation).StationDescription!,
            }}
          />
        )}
      </StationHeaderBlock>
    </StationHeader>
  );
};
