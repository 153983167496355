import styled, { css } from 'styled-components';

import { ModalBody } from '@/shared/services/modal';
import type { IDefaultTheme } from '@/shared/themes/types';

export const ModalBodyWrapper = styled(ModalBody)`
  padding: 16px 0 32px 0;
`;

export const WrapperFilters = styled.div`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    display: block;
    position: relative;

    ${theme.media.tablet} {
      display: inline-block;
      width: 100%;
    }
  `}
`;

export const FilterSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #000;
  margin-bottom: 16px;
`;

export const SearchInput = styled.input`
  ${({ isText }: { isText: boolean }) => css`
    font-size: 100%;
    background: #ededed00;
    border: solid 0px #0089ff;
    padding: 2px 26px 2px 27px;
    width: 130px;
    color: black;
    cursor: pointer;
    border-radius: 10em;
    transition: 'width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s';
    height: 30px;

    &:not(:focus) {
      border: ${isText ? '2px solid' : 'none'};
      width: ${isText ? 'unset' : '15px'};
      min-width: ${isText ? '130px' : '15px'};
      max-width: ${isText ? '260px' : '15px'};
    }

    &:focus {
      border: 2px solid red;
      width: unset;
      min-width: 130px;
      max-width: 260px;
      transition: 0.3s;
    }
  `}
`;

export const SearchInputClean = styled.button`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 100px;
    background-color: transparent;
    border: none;
    padding: 0px;
    color: black;
    position: absolute;
    right: 7px;
    top: 5px;

    &:focus {
      outline: 2px solid ${theme.defaultPrimaryColor};
      outline-offset: 0px;
    }
  `}
`;

export const WrapperContent = styled.div`
  margin: 0px 31px 0px 33px;
`;

export const TextSpan = styled.span`
  margin: 6px 8px 6px 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000000;
  width: max-content;
`;

export const ButtonOutline = styled.button`
  ${({ theme, isActive }: { theme: IDefaultTheme; isActive: boolean }) => css`
    width: auto;
    font-size: 16px;
    margin: 0 1em 1em 0;
    background-color: ${isActive ? theme.defaultPrimaryColor : 'unset'};
    border: 1px solid ${theme.defaultPrimaryColor};
    border-radius: 4px;
    color: ${isActive ? 'white' : theme.defaultPrimaryColor};
    line-height: 24px;
    overflow: hidden;
    padding: 0.5em 1.5em;
    text-transform: capitalize;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    &:focus {
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      outline: #000 solid 2px;
      outline-offset: -4px;
    }
  `}
`;

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
`;

export const Disclaimer = styled.p`
  text-align: left;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
`;

export const ModalSubheader = styled.label`
  height: 19px;
  margin: 0 21px 8px 0;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
`;

export const MainWrapper = styled.div`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    position: relative;
    display: unset;

    ${theme.media.tablet} {
      display: inline-block;
    }
  `}
`;
