import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { FILTERS_TYPES } from '@/entities/menu';

import { ButtonSecond } from '@/shared/components/Button';
import { ModalOne } from '@/shared/services/modal';
import type { TPortalModalComponent } from '@/shared/services/modal/type';

import type { ITrackedFilters, TFilters } from '../../../types';
import { Disclaimer, ModalBodyWrapper, WrapperButtons, WrapperContent } from '../../parts';
import FilterItem from '../blocks/FilterItem';
import FilterItems from '../blocks/FilterItems';

interface IProps {
  title: string;
  setFocus: VoidFunction;
  handleSaveClick: (filters: ITrackedFilters | TFilters) => void;
  trackedFilters: ITrackedFilters | TFilters;
  isAllergens?: boolean;
}

const ModalFilters: TPortalModalComponent<IProps> = ({
  title,
  close,
  setFocus,
  trackedFilters,
  handleSaveClick,
  isAllergens,
}) => {
  const [filters, setFilters] = useState(trackedFilters);
  const { formatMessage } = useIntl();
  const messages = {
    menuDisclaimer: formatMessage({ id: 'menuDisclaimer' }),
    save: formatMessage({ id: 'Save' }),
    dietsFilterCaption: formatMessage({ id: 'dietsFilterCaption' }),
    allergernsFilterCaption: formatMessage({ id: 'allergernsFilterCaption' }),
  };

  const handleClose = useCallback((): void => {
    close();
    setFocus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = (): void => {
    handleSaveClick(filters);
    close();
    setFocus();
  };

  const isAllFilters = !Array.isArray(filters);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const changeFilterStatusById = (filters: TFilters, id: string): TFilters => {
    return filters.map((filter) =>
      filter.Id === id ? { ...filter, IsActive: !filter?.IsActive } : filter,
    );
  };

  const { ALLERGENS, SPECIAL_DIETS } = FILTERS_TYPES;

  const handlerClickFilterItem = (id: string, type?: string): void => {
    if (isAllFilters && type) {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      setFilters((filters) => {
        const { allergens, specialDiets } = filters as ITrackedFilters;
        if (type === ALLERGENS) {
          const newAllergens = changeFilterStatusById(allergens, id);
          return { allergens: newAllergens, specialDiets };
        }

        const newSpecialDiets = changeFilterStatusById(specialDiets, id);
        return { specialDiets: newSpecialDiets, allergens };
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      setFilters((filters) => changeFilterStatusById(filters as TFilters, id));
    }
  };

  let filtersList;
  if (isAllFilters) {
    const { allergens, specialDiets } = filters;

    const allergensList = allergens.map((filter) => {
      return (
        <FilterItem key={filter.Id} {...filter} onClick={handlerClickFilterItem} type={ALLERGENS} />
      );
    });
    const allergensItems = (
      <FilterItems htmlText={messages.allergernsFilterCaption}>{allergensList}</FilterItems>
    );

    const specialDietsList = specialDiets.map((filter) => {
      return (
        <FilterItem
          key={filter.Id}
          {...filter}
          onClick={handlerClickFilterItem}
          type={SPECIAL_DIETS}
        />
      );
    });
    const specialDietsItems = (
      <FilterItems htmlText={messages.dietsFilterCaption}>{specialDietsList}</FilterItems>
    );

    filtersList = (
      <>
        {allergensItems}
        {specialDietsItems}
      </>
    );
  } else {
    const items = (filters as TFilters).map((filter) => {
      return <FilterItem key={filter.Id} {...filter} onClick={handlerClickFilterItem} />;
    });

    const htmlText = isAllergens ? messages.allergernsFilterCaption : messages.dietsFilterCaption;
    filtersList = <FilterItems htmlText={htmlText}>{items}</FilterItems>;
  }

  const footer = (
    <WrapperButtons>
      <ButtonSecond isDark className="Ok" click={handleSave}>
        <span
          dangerouslySetInnerHTML={{
            __html: messages.save,
          }}
        />
      </ButtonSecond>
    </WrapperButtons>
  );

  return (
    <ModalOne title={title} handlerClose={handleClose} footer={footer} isFocusOnCloseButton={false}>
      <ModalBodyWrapper className="ModalBodyWrapper">
        <WrapperContent>
          {filtersList}
          <Disclaimer
            dangerouslySetInnerHTML={{
              __html: messages?.menuDisclaimer?.toUpperCase(),
            }}
          />
        </WrapperContent>
      </ModalBodyWrapper>
    </ModalOne>
  );
};

export default ModalFilters;
