import chunk from 'lodash/chunk';
import toLower from 'lodash/toLower';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { toQuery, useMediaQuery } from 'react-responsive';

import { getCategories, getMenu } from '@/entities/menu';
import { getSearchText } from '@/entities/menu/filter';
import {
  getActiveStantions,
  getFavoriteProducts,
  getFilterProducts,
  getIsPaypalOnList,
  getIsProducts,
} from '@/entities/menu/product';

import { useAppSelector } from '@/shared/hooks/appRedux';
import { usePayPal } from '@/shared/services/paypal';
import type { TProducts } from '@/shared/types/menu';

import { WeeklyMenu } from '../WeeklyMenu';
import MenuItemsBlock from './MenuItemsBlock';
import { StationHeaderComponent } from './blocks/StationHeader';
import {
  CategoryName,
  MenuContainer,
  MenuDaily,
  MenuParentCategory,
  MenuStation,
  MenuWrapperDaily,
  NoProduct,
} from './parts';

const COUNT_PRODUCT_CARDS = {
  LAPTOP: 3,
  TABLET: 2,
  MOBILE: 1,
};

const CommonMenu: React.FC = () => {
  const isLaptop = useMediaQuery({
    query: toQuery({
      minWidth: 1024,
    }),
  });
  const isTablet = useMediaQuery({
    query: toQuery({
      minWidth: 768,
    }),
  });
  const isMobile = useMediaQuery({
    query: toQuery({
      maxWidth: 767,
    }),
  });
  const { formatMessage } = useIntl();

  const isPaypalOnList = useAppSelector(getIsPaypalOnList);
  usePayPal({
    clientId: window.menus?.paypal_client_id,
    isLoadScript: isPaypalOnList,
  });

  const searchInputText = useAppSelector(getSearchText);

  const messages = {
    NoFiltersMenu: formatMessage({ id: 'NoFiltersMenu' }),
    noMenu: formatMessage({ id: 'NoMenu' }),
    NoFoodOrderMenu: formatMessage({ id: 'NoFoodOrderMenu' }),
    favorites: formatMessage({ id: 'Favorites' }),
    NoMenuNoneFound: formatMessage({ id: 'NoMenuNoneFound' }, { 0: `"${searchInputText}"` }),
  };
  const count = useMemo(() => {
    if (isLaptop) return COUNT_PRODUCT_CARDS.LAPTOP;
    if (isTablet) return COUNT_PRODUCT_CARDS.TABLET;
    if (isMobile) return COUNT_PRODUCT_CARDS.MOBILE;
    return COUNT_PRODUCT_CARDS.LAPTOP;
  }, [isMobile, isTablet, isLaptop]);

  const divideListProduct = useCallback((products: TProducts) => chunk(products, count), [count]);

  const favoriteProducts = useAppSelector(getFavoriteProducts);

  const isProducts = useAppSelector(getIsProducts);
  const filterProducts = useAppSelector(getFilterProducts);

  const menus = useAppSelector(getMenu);
  const categories = useAppSelector(getCategories);
  const isFavorites = menus?.model.UserFavoritiesEnabled && favoriteProducts.length > 0;

  const activeStantions = useAppSelector(getActiveStantions);

  const stationViews = activeStantions.map((station) => {
    if (menus?.model.Menu.ShowCategories) {
      const productByStation = filterProducts.filter((p) => p.StationId === station.StationId);
      if (productByStation.length !== 0) {
        const shownCategories: string[] = [];
        return (
          <MenuStation
            key={station.StationId}
            className="MenuStation"
            role="group"
            id={station.StationId}
            aria-labelledby={station.StationId}
          >
            <StationHeaderComponent station={station} />
            {categories?.map((category) => {
              const categoryDisplayName = category.DisplayName;
              if (categoryDisplayName && !shownCategories.includes(categoryDisplayName)) {
                shownCategories.push(categoryDisplayName);

                const productsByCategory = productByStation.filter((p) =>
                  p.Product.Categories?.some(
                    (c) =>
                      c.CategoryId === category.CategoryId || c.DisplayName === categoryDisplayName,
                  ),
                );
                if (productsByCategory.length !== 0) {
                  const productsWithoutDeemphasized = productsByCategory.filter(
                    (x) => !x.IsDeemphasized,
                  );
                  const productsWithDeemphasized = productsByCategory.filter(
                    (x) => x.IsDeemphasized,
                  );

                  const getProductsWithoutDeemphasized = divideListProduct(
                    productsWithoutDeemphasized,
                  );

                  const getProductsWithDeemphasized = divideListProduct(productsWithDeemphasized);

                  return (
                    <MenuParentCategory
                      key={category.CategoryId}
                      className="MenuParentCategory"
                      data-category={toLower(category.CategoryId)}
                    >
                      <CategoryName className="CategoryName">{category.DisplayName}</CategoryName>
                      <MenuItemsBlock
                        productsWithoutDeemphasized={getProductsWithoutDeemphasized}
                        productsWithDeemphasized={getProductsWithDeemphasized}
                        station={station}
                        IsDaily={menus.model.IsDaily}
                      />
                    </MenuParentCategory>
                  );
                }
              }
              return null;
            })}
          </MenuStation>
        );
      }

      return null;
    }

    const productsWithoutDeemphasized = filterProducts.filter(
      (x) => (x.StationId === station.StationId || menus?.model.IsFavorites) && !x.IsDeemphasized,
    );
    const productsWithDeemphasized = filterProducts.filter(
      (x) => x.StationId === station.StationId && x.IsDeemphasized,
    );

    if (productsWithoutDeemphasized.length !== 0 || productsWithDeemphasized.length !== 0) {
      const getProductsWithoutDeemphasized = divideListProduct(productsWithoutDeemphasized);

      const getProductsWithDeemphasized = divideListProduct(productsWithDeemphasized);

      return (
        <MenuStation
          key={station.StationId}
          className="MenuStation_no-categories"
          aria-labelledby={station.StationId}
          id={station.StationId}
        >
          <StationHeaderComponent station={station} />
          <MenuItemsBlock
            productsWithoutDeemphasized={getProductsWithoutDeemphasized}
            productsWithDeemphasized={getProductsWithDeemphasized}
            station={station}
            IsDaily={!!menus?.model.IsDaily}
          />
        </MenuStation>
      );
    }

    return null;
  });

  const getDivideListFavoriteProducts = divideListProduct(favoriteProducts);

  const isAnyViews = stationViews.length > 0 && stationViews.some((item) => !!item);

  const textNoMenuForDaily =
    (!!searchInputText && messages.NoMenuNoneFound) ||
    (!isProducts && messages.noMenu) ||
    messages.NoFiltersMenu;
  const textNoMenuForFoodOrder =
    (!!searchInputText && messages.NoMenuNoneFound) ||
    (!isProducts && messages.NoFoodOrderMenu) ||
    messages.NoFiltersMenu;

  return (
    <MenuContainer className="MenuContainer">
      {(menus?.model.IsDaily || menus?.model.IsFoodOrder) && (
        <MenuDaily className="MenuDaily">
          <MenuWrapperDaily className="MenuWrapperDaily">
            {isFavorites && (
              <MenuStation className="MenuStationFavorites" id="1">
                <StationHeaderComponent
                  station={{
                    Name: messages.favorites,
                  }}
                  withOutImg
                />
                <MenuItemsBlock
                  productsWithoutDeemphasized={getDivideListFavoriteProducts}
                  IsDaily={menus.model.IsDaily}
                />
              </MenuStation>
            )}
            {isAnyViews && stationViews}
            <NoProduct
              isShow={!isAnyViews}
              role="alert"
              aria-live="polite"
              dangerouslySetInnerHTML={{
                __html: menus.model.IsDaily ? textNoMenuForDaily : textNoMenuForFoodOrder,
              }}
            />
          </MenuWrapperDaily>
        </MenuDaily>
      )}
      {menus?.model.IsWeekly && <WeeklyMenu />}
    </MenuContainer>
  );
};

export default CommonMenu;
