import styled, { css } from 'styled-components';

import type { IDefaultTheme } from '@/shared/themes/types';

export const DateMealFilterButtonWrapper = styled.div`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    width: 100%;
    border: none;
    height: 40px;
    margin: 1px 1px 16px 1px;
    padding: 4px 2px 2px 21px;
    border-radius: 22.5px;
    background-color: #efefef;
    display: inline-flex;
    position: relative;
    box-sizing: border-box;

    ${theme.media.tablet} {
      width: auto;
      padding: 1px 2px 2px 16px;
      margin: 1px 16px 1px 1px;
    }
  `}
`;
export const DateMealFilterButton = styled.button`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    width: 84px;
    height: 38px;
    padding: 6px 16px;
    background-color: #fff;
    border-radius: 0px 22.5px 22.5px 0px;
    border: none;
    outline: none;
    position: absolute;
    right: 1px;
    top: 1px;

    &:focus {
      border: 2px solid;
    }
    ${theme.media.tablet} {
      position: relative;
      right: 0;
      top: 0;
    }
  `}
`;

export const MealFilter = styled.div`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    margin-top: 0px;

    ${theme.media.tablet} {
      margin-top: 16px;
    }
  `}
`;
export const DateMealFilterSpan = styled.div`
  ${({ theme }: { theme: IDefaultTheme }) => css`
    height: 24px;

    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: right;
    color: ${theme.defaultPrimaryColor};
  `}
`;
